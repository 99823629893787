// External libraries
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
// Types
import { DeleteRoleInput, UpdateRoleInput, CreateRoleInput } from '../API';
// Utilities
import { clean } from 'utils/Helpers';
// Internal modules and GraphQL mutations
import {
  deleteRole as deleteRoleMutation,
  updateRole as updateRoleMutation,
  createRole as createRoleMutation,
} from '../graphql/mutations';
import { notificationService } from './notificationService';

class RoleService {
  async remove(id: string) {
    try {
      const response = await API.graphql<GraphQLQuery<DeleteRoleInput>>(
        graphqlOperation(deleteRoleMutation, {
          input: { id },
        }),
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async create(values: CreateRoleInput) {
    try {
      if (!values) {
        throw new Error('No role values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<CreateRoleInput>>(
        graphqlOperation(createRoleMutation, { input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Role successfully created.');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async update(values: UpdateRoleInput) {
    try {
      if (!values?.id) {
        throw new Error('No role id provided.');
      }
      if (!values) {
        throw new Error('No role values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<UpdateRoleInput>>(
        graphqlOperation(updateRoleMutation, { id: values.id, input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Role updated');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }
}

export const roleService = new RoleService();
