// React and other external libraries
import { Plus } from '@phosphor-icons/react';
import { Button, Stack, Group } from '@mantine/core';
// Internal modules and components
import { RoleTable } from 'components/roles/RoleTable';
import { RoleForm } from 'components/roles/RoleForm';
import { modalService } from 'services/modalService';
import { useGraphQLSubscription } from 'hooks/useGraphQLSubscription';
import {
  onCreateRole,
  onDeleteRole,
  onUpdateRole,
} from 'graphql/subscriptions';
import { listRoles } from 'graphql/queries';
import { Role } from 'API';

export function Roles() {
  const { data: roles } = useGraphQLSubscription<Role>({
    list: listRoles,
    listKey: 'listRoles',
    onCreate: onCreateRole,
    onCreateKey: 'onCreateRole',
    onUpdate: onUpdateRole,
    onUpdateKey: 'onUpdateRole',
    onDelete: onDeleteRole,
    onDeleteKey: 'onDeleteRole',
  });

  return (
    <Stack>
      <Group position="right">
        {/* CREATE */}
        <Button
          leftIcon={<Plus size={18} />}
          color="orange"
          onClick={() => {
            modalService.form({
              title: 'Create Role',
              size: 'xl',
              children: <RoleForm closeModal={modalService.close} />,
              onClose: () => null,
            });
          }}
        >
          Create
        </Button>
      </Group>
      {/* TABLE */}
      <RoleTable rolees={roles} />
    </Stack>
  );
}
