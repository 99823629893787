import { Storage } from 'aws-amplify';

Storage.configure({
  AWSS3: {
    bucket: 'solidus-bucket-dev164109-dev',
    region: 'us-east-1',
  },
});

class S3Service {
  async uploadFile(files: File[], names: string[]) {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (typeof file !== 'string') {
          const objKey = names[i];
          console.log('file', i, file);
          console.log('objKey', i, objKey);
          await Storage.put(objKey, file, {
            contentType: file.type,
          });
        }
      }
    } catch (e) {
      console.log('error uploading', e);
      return null;
    }
  }

  async deleteFile(key: string) {
    try {
      await Storage.remove(key, { level: 'public' });
    } catch (e) {
      console.log('error deleting', e);
      return null;
    }
  }
}

export const s3Service = new S3Service();
