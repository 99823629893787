// React and other external libraries
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, Group, Stack } from '@mantine/core';
// Internal modules and components
import { RoleUser, User } from 'API';
import { userService } from 'services/userService';
import { FormikTextInput } from 'components/forms/ForikTextInput';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';

interface UserListFormProps {
  userData?: User;
  closeModal: () => void;
}

interface FormValues {
  name: string;
  email: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).required('Required'),
  email: Yup.string().email().required('Required'),
});

export const UserForm = ({ userData, closeModal }: UserListFormProps) => {
  const [, setRoles] = useState<Array<RoleUser | null>>([null]);

  const initialValues = {
    id: userData?.id ?? '',
    name: userData?.name ?? '',
    email: userData?.email ?? '',
  };

  useEffect(() => {
    // if (userData) {
    //   setInitialValues({
    //     id: userData.id,
    //     name: userData.name,
    //     email: userData.email,
    //   });
    // }
    if (userData?.role?.items) {
      setRoles(userData?.role.items);
    }
  }, [userData]);

  const handleUpdate = async ({ name, email }: FormValues) => {
    await userService.update({ id: userData?.id ?? '', name, email });
    closeModal();
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
    >
      {props => {
        const { handleSubmit, isSubmitting, isValid, dirty } = props;
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing="md">
              <FormikTextInput
                name="name"
                label="Name"
                placeholder="Enter a name"
              />
              <FormikTextInput
                name="email"
                label="Email"
                placeholder="Enter an email"
              />
              <Group position="right">
                <Button
                  type="submit"
                  color="orange"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  {userData ? 'Update' : 'Create'}
                </Button>
                <Button
                  type="button"
                  variant="outline"
                  color="orange"
                  onClick={closeModal}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Group>
            </Stack>
            {/* DEV TOOLS */}
            {process.env.NODE_ENV === 'development' && (
              <DisplayFormikState {...props} />
            )}
          </form>
        );
      }}
    </Formik>
  );
};
