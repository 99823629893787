// External libraries
import React, { useState, useEffect, useRef } from 'react';
import { Amplify, Storage, API, Auth, Hub } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import awsconfig from './aws-exports';
import {
  MantineProvider,
  ColorSchemeProvider,
  LoadingOverlay,
  ColorScheme,
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalsProvider } from '@mantine/modals';
// Services
import { userService } from 'services/userService';
// User Context
import { UserProvider } from './contexts/UserContext';
// Components and Pages
// import TheApp from './pages/TheApp';
// import { Login } from 'pages/Login';
// Theme and Styling
import { theme } from 'theme/theme';
import { CustomFonts } from 'theme/fonts/CustomFonts';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { EnvValidator } from 'validations/EnvValidator';
import { Routes } from 'routes/Routes';

Amplify.configure(awsconfig);
API.configure(awsconfig);
Storage.configure(awsconfig);

const queryClient = new QueryClient();

function App() {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userDocumentId, setUserDocumentId] = useState<string | null>(null);
  const [authBusinesses, setAuthBusinesses] = useState<string[] | null>(null);
  const lockRef = useRef(false);

  useEffect(() => {
    // Check user on initial load
    checkUser();
    // Listen for auth events
    const unsubscribe = Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          checkUser(); // update state on sign-in
          break;
        case 'signOut':
          setCognitoUser(null); // update state on sign-out
          break;
        default:
          break;
      }
    });

    return () => unsubscribe(); // cleanup
  }, []);

  async function checkUser() {
    try {
      const cognitoUser: CognitoUser | null =
        await Auth.currentAuthenticatedUser();
      setCognitoUser(cognitoUser);
      const { email, name } = (cognitoUser as any).signInUserSession.idToken
        .payload;
      const { data, ok } = await userService.getUserByUserId(
        cognitoUser?.getUsername() as string,
      );

      // Create user if it doesn't exist
      if (!data && ok && !lockRef.current) {
        lockRef.current = true;
        userService.create({
          email,
          userId: cognitoUser?.getUsername() as string,
          name: name ?? '',
        });
      } else {
        if (data?.business?.items) {
          setAuthBusinesses(
            data?.business?.items?.map((item: any) => item.businessId),
          );
        }
        setUserDocumentId(data?.id ?? null);
      }
    } catch (err) {
      setCognitoUser(null);
    }
    setIsAuthenticating(false);
  }
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  if (isAuthenticating) {
    return <LoadingOverlay visible overlayBlur={2} />;
  }

  return (
    <UserProvider
      value={
        cognitoUser
          ? {
              cognitoUser,
              groups: (cognitoUser as any).signInUserSession.idToken.payload[
                'cognito:groups'
              ],
              authBusiness: authBusinesses,
              isAdmin: (cognitoUser as any).signInUserSession.idToken.payload[
                'cognito:groups'
              ]?.includes('Admin'),
              user: {
                email: (cognitoUser as any).signInUserSession.idToken.payload
                  .email,
                id: userDocumentId,
                name: (cognitoUser as any).signInUserSession.idToken.payload
                  .name,
              },
              logout: () => Auth.signOut(),
              isInitialized: true,
            }
          : {
              isInitialized: false,
              user: null,
              logout: () => Auth.signOut(),
              cognitoUser: null,
              groups: null,
              isAdmin: false,
              authBusiness: null,
            }
      }
    >
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{ ...theme, colorScheme }}
          withGlobalStyles
          withNormalizeCSS
        >
          <ModalsProvider>
            <Notifications />
            <CustomFonts />
            <EnvValidator />
            <QueryClientProvider client={queryClient}>
              <Routes />
              {/* {cognitoUser ? <TheApp /> : <Login />} */}
            </QueryClientProvider>
          </ModalsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </UserProvider>
  );
}

export default App;
