import { useMemo } from 'react';
import {
  Business,
  ModelBusinessUserConnection,
  ModelRoleUserConnection,
  Role,
  User,
} from 'API';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { Menu } from '@mantine/core';
import { modalService } from 'services/modalService';
import { userService } from 'services/userService';
import { UserForm } from './usersForm';
import { UserRolesForm } from './userRolesForm';
import { listBusinesses, listRoles } from 'graphql/queries';
import {
  onCreateRole,
  onUpdateRole,
  onDeleteRole,
  onCreateBusiness,
  onUpdateBusiness,
  onDeleteBusiness,
} from 'graphql/subscriptions';
import { useGraphQLSubscription } from 'hooks/useGraphQLSubscription';
import { UserBusinessForm } from './userBusinessForm';

export default function UsersListTable({ users }: { users: User[] | null }) {
  const { data: roles } = useGraphQLSubscription<Role>({
    list: listRoles,
    listKey: 'listRoles',
    onCreate: onCreateRole,
    onCreateKey: 'onCreateRole',
    onUpdate: onUpdateRole,
    onUpdateKey: 'onUpdateRole',
    onDelete: onDeleteRole,
    onDeleteKey: 'onDeleteRole',
  });
  const { data: businesses } = useGraphQLSubscription<Business>({
    list: listBusinesses,
    listKey: 'listBusinesses',
    onCreate: onCreateBusiness,
    onCreateKey: 'onCreateBusiness',
    onUpdate: onUpdateBusiness,
    onUpdateKey: 'onUpdateBusiness',
    onDelete: onDeleteBusiness,
    onDeleteKey: 'onDeleteBusiness',
  });
  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'UserID',
        accessorKey: 'id',
      },
      {
        header: 'Roles',
        accessorKey: 'role',
        Cell: ({ cell }) => {
          return cell
            .getValue<ModelRoleUserConnection>()
            .items?.map(role => role?.role?.name);
        },
      },
      {
        header: 'Businesses',
        accessorKey: 'business',
        Cell: ({ cell }) => {
          return cell
            .getValue<ModelBusinessUserConnection>()
            .items?.map(business => business?.business?.name);
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: users || [],
    enableRowActions: true,
    state: { isLoading: !users },
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          onClick={() =>
            modalService.form({
              title: 'Edit User',
              size: 'xl',
              children: (
                <UserForm
                  userData={row.original}
                  closeModal={modalService.close}
                />
              ),
              onClose: () => null,
            })
          }
        >
          Edit user
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            modalService.form({
              title: 'Edit Roles',
              size: 'xl',
              children: (
                <UserRolesForm
                  userData={row.original}
                  roles={roles}
                  closeModal={modalService.close}
                />
              ),
              onClose: () => null,
            })
          }
        >
          Edit role
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            modalService.form({
              title: 'Edit Business',
              size: 'xl',
              children: (
                <UserBusinessForm
                  userData={row.original}
                  business={businesses}
                  closeModal={modalService.close}
                />
              ),
              onClose: () => null,
            })
          }
        >
          Edit business
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            modalService.delete({
              title: `Delete ${row.original.name || 'User'}`,
              children: `Are you sure you want to delete ${
                row.original.name || 'this user'
              }?`,
              onConfirm: () => userService.remove(row.original.id),
            })
          }
        >
          Delete
        </Menu.Item>
      </>
    ),
  });

  return <MantineReactTable table={table} />;
}
