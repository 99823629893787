import { Carousel } from '@mantine/carousel';
import { Group } from '@mantine/core';
import { bucketURL } from 'config/bucketURL';

type ImagesCarouselProps = {
  images: string[];
  currentIndex: number;
};

export default function ImagesCarousel({
  images,
  currentIndex,
}: ImagesCarouselProps) {
  return (
    <div>
      {images.length !== 0 && (
        <Carousel
          withControls={images.length > 1}
          style={{ height: 400 }}
          initialSlide={currentIndex}
          loop
        >
          {images.map((image, index) => (
            <Carousel.Slide key={index}>
              <Group position="center" style={{ height: 400 }}>
                {typeof image === 'string' ? (
                  <img
                    src={`${bucketURL}/${image}`}
                    alt=""
                    style={{ borderRadius: 4 }}
                    width={400}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(image as any)}
                    alt=""
                    style={{ borderRadius: 4 }}
                    width={400}
                  />
                )}
              </Group>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
    </div>
  );
}
