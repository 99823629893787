// External libraries
import { Menu } from '@mantine/core';
import { useMemo } from 'react';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
// Internal types
import { Business, InspectionField, Template, User } from 'API';
// Services
import { modalService } from 'services/modalService';
import { TemplateForm } from './TemplateForm';
import { templateService } from 'services/templateService';
import { PencilSimple, X } from '@phosphor-icons/react';

type props = {
  templates: Template[] | null;
};

export function TemplatesTable({ templates }: props) {
  const data = useMemo(() => templates, [templates]);
  const columns = useMemo<MRT_ColumnDef<Template>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'User',
        accessorKey: 'User',
        Cell: ({ cell }) => {
          return cell.getValue<User>().email;
        },
      },
      {
        header: 'Business owner',
        accessorKey: 'Business',
        Cell: ({ cell }) => {
          return cell.getValue<Business>().name;
        },
      },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
      },
      {
        header: 'Fields',
        accessorKey: 'fields',
        Cell: ({ cell }) => (
          <pre>
            {JSON.stringify(
              cell.getValue<Array<InspectionField | null> | null>(),
            )}
          </pre>
        ),
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableRowActions: true,
    state: { isLoading: !data },
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          icon={<PencilSimple size={16} />}
          onClick={() => {
            modalService.form({
              title: 'Edit Template',
              size: 'xl',
              children: (
                <TemplateForm
                  templateData={row.original}
                  closeModal={modalService.close}
                />
              ),
              onClose: () => null,
            });
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          icon={<X size={16} />}
          onClick={() =>
            modalService.delete({
              title: `Delete ${row.original.name}?`,
              children: `Are you sure you want to delete ${row.original.name} template?`,
              onConfirm: () => templateService.remove(row.original.id),
            })
          }
        >
          Delete
        </Menu.Item>
      </>
    ),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
}
