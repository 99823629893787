// External libraries
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
// Types
import {
  DeleteUserInput,
  UpdateUserInput,
  CreateUserInput,
  GetUserQuery,
  CreateRoleUserInput,
  UpdateRoleUserInput,
  DeleteRoleUserInput,
  ListUsersQuery,
  CreateBusinessUserInput,
  DeleteBusinessUserInput,
  UpdateBusinessUserInput,
} from '../API';
// Utilities
import { clean } from 'utils/Helpers';
// Internal modules and GraphQL mutations
import {
  deleteUser,
  updateUser,
  createUser,
  createRoleUser,
  updateRoleUser,
  deleteRoleUser,
  createBusinessUser,
  deleteBusinessUser,
  updateBusinessUser,
} from '../graphql/mutations';
import { notificationService } from './notificationService';
import { getUser, listUsers } from 'graphql/queries';

class UserService {
  async remove(id: string) {
    try {
      const response = await API.graphql<GraphQLQuery<DeleteUserInput>>(
        graphqlOperation(deleteUser, {
          input: { id },
        }),
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async create({ userId, email, name }: CreateUserInput) {
    try {
      if (!userId || !email) {
        throw new Error('No user values provided.');
      }
      let payload = { ...clean({ userId, email, name }, true) };
      const response = await API.graphql<GraphQLQuery<CreateUserInput>>(
        graphqlOperation(createUser, { input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        // notificationService.success('User successfully created.');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = error.message || 'An unknown error occurred';
      }
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async update({ id, email, name }: UpdateUserInput) {
    try {
      if (!id) {
        throw new Error('No user id provided.');
      }
      if (!email && !name) {
        throw new Error('No user values provided.');
      }
      const response = await API.graphql<GraphQLQuery<UpdateUserInput>>(
        graphqlOperation(updateUser, { input: { id, email, name } }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('User updated');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = error.message || 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async getUserByUserId(userId: string) {
    try {
      if (!userId) {
        throw new Error('No user ID provided.');
      }

      const response = await API.graphql<GraphQLQuery<ListUsersQuery>>(
        graphqlOperation(listUsers, {
          filter: { userId: { eq: userId } },
        }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      const users = response.data?.listUsers?.items;
      if (users && users.length === 0) {
        return { ok: true, data: null };
      } else if (users && users.length > 1) {
        throw new Error('Multiple users found.');
      } else if (users && users.length === 1) {
        return { ok: true, data: users[0] };
      } else {
        return { ok: false, error: 'User not found.' };
      }
    } catch (error: any) {
      console.log('error', error);
      const errorMessage =
        error?.errors && Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors[0].message || 'An unknown error occurred'
          : 'An unknown error occurred';

      return { ok: false, error: errorMessage };
    }
  }

  async getUserById(userId: string) {
    try {
      if (!userId) {
        throw new Error('No user ID provided.');
      }

      const response = await API.graphql<GraphQLQuery<GetUserQuery>>(
        graphqlOperation(getUser, { id: userId }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        const user = response.data?.getUser; // Adjust the response structure based on your GraphQL schema
        return { ok: true, data: user };
      }
    } catch (error: any) {
      const errorMessage =
        error?.errors && Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors[0].message || 'An unknown error occurred'
          : error.message || 'An unknown error occurred';
      console.log(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  // ROLES
  async removeRole(userId: string, roleId: string) {
    try {
      // Find the RoleUser entry that matches the user ID and role ID
      const user = await this.getUserById(userId);

      if (!user.ok) {
        throw new Error('User not found.');
      }

      const roleUser = user?.data?.role?.items?.find(
        roleUser => roleUser?.roleId === roleId,
      );

      if (!roleUser) {
        throw new Error('Role not found for this user.');
      }

      // Delete the RoleUser entry
      const response = await API.graphql<GraphQLQuery<DeleteRoleUserInput>>(
        graphqlOperation(deleteRoleUser, { input: { id: roleUser.id } }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return { ok: true, message: 'Role removed from user.' };
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }
  async addRole(userId: string, roleId: string) {
    try {
      // Create a new RoleUser entry
      const input = {
        userId: userId,
        roleId: roleId,
      };

      const response = await API.graphql<GraphQLQuery<CreateRoleUserInput>>(
        graphqlOperation(createRoleUser, { input }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return { ok: true, message: 'Role added to user.' };
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async updateRole(userId: string, roleId: string) {
    try {
      // Update the existing RoleUser entry
      const user = await this.getUserById(userId);

      if (!user.ok) {
        throw new Error('User not found.');
      }

      const roleUser = user.data?.role?.items?.find(
        roleUser => roleUser?.roleId === roleId,
      );

      if (!roleUser) {
        throw new Error('Role not found for this user.');
      }

      const input = {
        id: roleUser.id,
        roleId: roleId,
        userId: userId,
      };

      const response = await API.graphql<GraphQLQuery<UpdateRoleUserInput>>(
        graphqlOperation(updateRoleUser, { input }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return { ok: true, message: 'User role updated.' };
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }
  // BUSINESS
  async addBusiness(userId: string, businessId: string) {
    if (!userId) {
      throw new Error('No user ID provided.');
    }
    if (!businessId) {
      throw new Error('No business ID provided.');
    }
    try {
      const input = {
        userId: userId,
        businessId: businessId,
      };

      const response = await API.graphql<GraphQLQuery<CreateBusinessUserInput>>(
        graphqlOperation(createBusinessUser, { input }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return { ok: true, message: 'Business added to user.' };
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async updateBusiness(userId: string, businessId: string) {
    try {
      const user = await this.getUserByUserId(userId);
      const usersBusiness = user?.data?.business?.items?.find(
        businessUser => businessUser?.businessId === businessId,
      );

      if (!usersBusiness) {
        throw new Error('Business not found for this user.');
      }

      const input = {
        id: usersBusiness.id,
        businessId: businessId,
        userId: userId,
      };

      const response = await API.graphql<GraphQLQuery<UpdateBusinessUserInput>>(
        graphqlOperation(updateBusinessUser, { input }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return { ok: true, message: 'Business role updated.' };
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async removeBusiness(userId: string, businessId: string) {
    try {
      const user = await this.getUserById(userId);

      if (!user?.ok) {
        throw new Error('User not found.');
      }

      console.log('user', user);
      const usersBusiness = user?.data?.business?.items?.find(
        businessUser => businessUser?.businessId === businessId,
      );

      if (!usersBusiness) {
        throw new Error('Business not found for this user.');
      }

      // Delete the BusinessRoleUser entry
      const response = await API.graphql<GraphQLQuery<DeleteBusinessUserInput>>(
        graphqlOperation(deleteBusinessUser, {
          input: { id: usersBusiness.id },
        }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return { ok: true, message: 'Business role removed from user.' };
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }
}

export const userService = new UserService();
