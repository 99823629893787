import { Title } from '@mantine/core';
import { LoginForm } from 'components/auth/LoginForm';
import { AuthLayout } from 'layout/auth/AuthLayout';

export function Login() {
  return (
    <AuthLayout
      title={
        <Title mt={40} ta={'center'}>
          Welcome back to MineCheck
        </Title>
      }
    >
      <LoginForm />
    </AuthLayout>
  );
}
