import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Stack } from '@mantine/core';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { Auth } from 'aws-amplify';
import { notificationService } from 'services/notificationService';
import { FormikPinInput } from 'components/forms/ForikPinInput';
import { FormikTextInput } from 'components/forms/ForikTextInput';

type Props = {
  email: string;
  onVerificationSuccess: () => void;
};

type VerifyFormProps = {
  code: string;
  email: string;
};

export const VerifyForm = ({ email, onVerificationSuccess }: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    code: Yup.string()
      .length(6, 'Pin must be 6 characters')
      .required('Pin is required'),
  });

  const handleSubmit = async (
    { code, email }: VerifyFormProps,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      await Auth.confirmSignUp(email, code);
      setSubmitting(false);
      onVerificationSuccess();
      notificationService.success('Account verified.');
    } catch (error: any) {
      console.error(error);
      notificationService.error(error.message);
    }
  };

  return (
    <Formik<VerifyFormProps>
      initialValues={{ code: '', email: email }}
      validationSchema={validationSchema}
      onSubmit={({ code, email }, { setSubmitting }) =>
        handleSubmit({ code, email }, setSubmitting)
      }
    >
      {props => {
        const { isSubmitting, isValid } = props;
        return (
          <Form>
            <Stack>
              <FormikTextInput
                label="Email address"
                name="email"
                placeholder="hello@gmail.com"
                disabled={email !== ''}
              />
              <FormikPinInput name="code" />
            </Stack>
            <Button
              type="submit"
              color="orange"
              fullWidth
              mt="xl"
              size="md"
              loading={isSubmitting}
              disabled={!isValid}
            >
              Submit
            </Button>
            {/* DEV TOOLS */}
            {process.env.NODE_ENV === 'development' && (
              <DisplayFormikState {...props} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
