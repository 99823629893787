/**
 * Notification Service
 *
 * This service provides functions for displaying notifications with different severity levels such as success, error, warning, and info.
 * Each function takes a message, an optional title, and an optional options object that can be used to override default properties.
 *
 * Example Usage:
 *
 * import notificationService from './notificationService';
 *
 * notificationService.success('Custom success message', 'Custom Title', {
 *   color: 'blue', // Overrides the default green color
 *   withCloseButton: false, // Overrides the default true value
 *   autoClose: 10000, // Overrides the default 5000ms value
 * });
 *
 * This call would display a success notification with a blue color, no close button, and an auto-close time of 10 seconds, overriding the default values set in the success method.
 */

import { notifications } from '@mantine/notifications';

export const notificationService = {
  success: (
    message: string,
    title: string = 'Success',
    options: object = {},
  ) => {
    notifications.show({
      title,
      message,
      color: 'green',
      withCloseButton: true,
      autoClose: 5000,
      ...options,
    });
  },
  error: (message: string, title: string = 'Error', options: object = {}) => {
    notifications.show({
      title,
      message,
      color: 'red',
      withCloseButton: true,
      autoClose: 5000,
      ...options,
    });
  },
  warning: (
    message: string,
    title: string = 'Warning',
    options: object = {},
  ) => {
    notifications.show({
      title,
      message,
      color: 'yellow',
      withCloseButton: true,
      autoClose: 5000,
      ...options,
    });
  },
  info: (message: string, title: string = 'Info', options: object = {}) => {
    notifications.show({
      title,
      message,
      color: 'blue',
      withCloseButton: true,
      autoClose: 5000,
      ...options,
    });
  },
};
