// React and other external libraries
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button, Group, Stack } from '@mantine/core';
// Internal modules and components
import { Business, User } from 'API';
import { userService } from 'services/userService';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { FormikSelectInput } from 'components/forms/FormikSelectInput';

interface UserBusinessFormProps {
  userData?: User;
  business: Business[] | null;
  closeModal: () => void;
}

interface FormValues {
  businessId: string | null;
}

const validationSchema = Yup.object().shape({
  businessId: Yup.string().nullable(),
});

export const UserBusinessForm = ({
  userData,
  business,
  closeModal,
}: UserBusinessFormProps) => {
  const [initialValues] = useState<FormValues>({
    businessId: userData?.business?.items[0]?.businessId || '',
  });

  const handleCreate = async ({ businessId }: FormValues) => {
    if (userData?.id && businessId) {
      await userService.addBusiness(userData?.id, businessId);
      closeModal();
    } else if (userData?.id && userData.business && businessId === null) {
      console.log('remove business');
      console.log(userData?.business?.items[0]?.businessId);
      console.log(userData?.id);
      await userService.removeBusiness(
        userData?.id,
        userData?.business?.items[0]?.businessId as string,
      );
      closeModal();
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Stack spacing="md">
            <FormikSelectInput
              name="businessId"
              label="Business"
              options={
                business?.map(business => ({
                  value: business.id,
                  label: business.name,
                })) || []
              }
              clearable={true}
            />
            <Group position="right">
              <Button
                type="submit"
                color="orange"
                loading={props.isSubmitting}
                disabled={!props.dirty || !props.isValid}
              >
                {userData ? 'Update' : 'Create'}
              </Button>
              <Button
                type="button"
                variant="outline"
                color="orange"
                onClick={closeModal}
                disabled={props.isSubmitting}
              >
                Cancel
              </Button>
            </Group>
          </Stack>
          {/* DEV TOOLS */}
          {process.env.NODE_ENV === 'development' && (
            <DisplayFormikState {...props} />
          )}
        </form>
      )}
    </Formik>
  );
};
