import { useEffect } from 'react';
import { envValidationSchema } from './envValidationSchema';
import { notificationService } from '../services/notificationService';

export function EnvValidator() {
  useEffect(() => {
    async function validateEnv() {
      try {
        await envValidationSchema.validate(process.env);
      } catch (error: any) {
        console.log(error);
        notificationService.error(
          error.message,
          'Environment Variables Validation Error',
        );
      }
    }

    validateEnv();
  }, []);

  return null;
}
