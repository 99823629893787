/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBusiness = /* GraphQL */ `
  subscription OnCreateBusiness($filter: ModelSubscriptionBusinessFilterInput) {
    onCreateBusiness(filter: $filter) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBusiness = /* GraphQL */ `
  subscription OnUpdateBusiness($filter: ModelSubscriptionBusinessFilterInput) {
    onUpdateBusiness(filter: $filter) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBusiness = /* GraphQL */ `
  subscription OnDeleteBusiness($filter: ModelSubscriptionBusinessFilterInput) {
    onDeleteBusiness(filter: $filter) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
    onCreateRole(filter: $filter) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
    onUpdateRole(filter: $filter) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
    onDeleteRole(filter: $filter) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInspection = /* GraphQL */ `
  subscription OnCreateInspection(
    $filter: ModelSubscriptionInspectionFilterInput
  ) {
    onCreateInspection(filter: $filter) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInspection = /* GraphQL */ `
  subscription OnUpdateInspection(
    $filter: ModelSubscriptionInspectionFilterInput
  ) {
    onUpdateInspection(filter: $filter) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInspection = /* GraphQL */ `
  subscription OnDeleteInspection(
    $filter: ModelSubscriptionInspectionFilterInput
  ) {
    onDeleteInspection(filter: $filter) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInspector = /* GraphQL */ `
  subscription OnCreateInspector(
    $filter: ModelSubscriptionInspectorFilterInput
  ) {
    onCreateInspector(filter: $filter) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateInspector = /* GraphQL */ `
  subscription OnUpdateInspector(
    $filter: ModelSubscriptionInspectorFilterInput
  ) {
    onUpdateInspector(filter: $filter) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteInspector = /* GraphQL */ `
  subscription OnDeleteInspector(
    $filter: ModelSubscriptionInspectorFilterInput
  ) {
    onDeleteInspector(filter: $filter) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTemplate = /* GraphQL */ `
  subscription OnCreateTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onCreateTemplate(filter: $filter) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const onUpdateTemplate = /* GraphQL */ `
  subscription OnUpdateTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onUpdateTemplate(filter: $filter) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const onDeleteTemplate = /* GraphQL */ `
  subscription OnDeleteTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onDeleteTemplate(filter: $filter) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const onCreateBusinessUser = /* GraphQL */ `
  subscription OnCreateBusinessUser(
    $filter: ModelSubscriptionBusinessUserFilterInput
  ) {
    onCreateBusinessUser(filter: $filter) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBusinessUser = /* GraphQL */ `
  subscription OnUpdateBusinessUser(
    $filter: ModelSubscriptionBusinessUserFilterInput
  ) {
    onUpdateBusinessUser(filter: $filter) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBusinessUser = /* GraphQL */ `
  subscription OnDeleteBusinessUser(
    $filter: ModelSubscriptionBusinessUserFilterInput
  ) {
    onDeleteBusinessUser(filter: $filter) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRoleUser = /* GraphQL */ `
  subscription OnCreateRoleUser($filter: ModelSubscriptionRoleUserFilterInput) {
    onCreateRoleUser(filter: $filter) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRoleUser = /* GraphQL */ `
  subscription OnUpdateRoleUser($filter: ModelSubscriptionRoleUserFilterInput) {
    onUpdateRoleUser(filter: $filter) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRoleUser = /* GraphQL */ `
  subscription OnDeleteRoleUser($filter: ModelSubscriptionRoleUserFilterInput) {
    onDeleteRoleUser(filter: $filter) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
