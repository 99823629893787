/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection(
    $input: CreateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    createInspection(input: $input, condition: $condition) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateInspection = /* GraphQL */ `
  mutation UpdateInspection(
    $input: UpdateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    updateInspection(input: $input, condition: $condition) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteInspection = /* GraphQL */ `
  mutation DeleteInspection(
    $input: DeleteInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    deleteInspection(input: $input, condition: $condition) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createInspector = /* GraphQL */ `
  mutation CreateInspector(
    $input: CreateInspectorInput!
    $condition: ModelInspectorConditionInput
  ) {
    createInspector(input: $input, condition: $condition) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateInspector = /* GraphQL */ `
  mutation UpdateInspector(
    $input: UpdateInspectorInput!
    $condition: ModelInspectorConditionInput
  ) {
    updateInspector(input: $input, condition: $condition) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteInspector = /* GraphQL */ `
  mutation DeleteInspector(
    $input: DeleteInspectorInput!
    $condition: ModelInspectorConditionInput
  ) {
    deleteInspector(input: $input, condition: $condition) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const createBusinessUser = /* GraphQL */ `
  mutation CreateBusinessUser(
    $input: CreateBusinessUserInput!
    $condition: ModelBusinessUserConditionInput
  ) {
    createBusinessUser(input: $input, condition: $condition) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBusinessUser = /* GraphQL */ `
  mutation UpdateBusinessUser(
    $input: UpdateBusinessUserInput!
    $condition: ModelBusinessUserConditionInput
  ) {
    updateBusinessUser(input: $input, condition: $condition) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBusinessUser = /* GraphQL */ `
  mutation DeleteBusinessUser(
    $input: DeleteBusinessUserInput!
    $condition: ModelBusinessUserConditionInput
  ) {
    deleteBusinessUser(input: $input, condition: $condition) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRoleUser = /* GraphQL */ `
  mutation CreateRoleUser(
    $input: CreateRoleUserInput!
    $condition: ModelRoleUserConditionInput
  ) {
    createRoleUser(input: $input, condition: $condition) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoleUser = /* GraphQL */ `
  mutation UpdateRoleUser(
    $input: UpdateRoleUserInput!
    $condition: ModelRoleUserConditionInput
  ) {
    updateRoleUser(input: $input, condition: $condition) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRoleUser = /* GraphQL */ `
  mutation DeleteRoleUser(
    $input: DeleteRoleUserInput!
    $condition: ModelRoleUserConditionInput
  ) {
    deleteRoleUser(input: $input, condition: $condition) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
