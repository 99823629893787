// React and other external libraries
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button, Group, Stack } from '@mantine/core';
// Internal modules and components
import { CreateRoleUserInput, Role, User } from 'API';
import { userService } from 'services/userService';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { FormikSelectInput } from 'components/forms/FormikSelectInput';

interface UserRolesFormProps {
  userData?: User;
  roles: Role[] | null;
  closeModal: () => void;
}

const validationSchema = Yup.object().shape({});

export const UserRolesForm = ({
  userData,
  roles,
  closeModal,
}: UserRolesFormProps) => {
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpdate] = useState(false);
  const [initialValues] = useState<CreateRoleUserInput>({
    roleId: userData?.role?.items[0]?.roleId || '',
    userId: userData?.id || '',
  });

  const handleCreate = async (values: CreateRoleUserInput) => {
    setLoadingCreate(true);
    await userService.addRole(values.userId, values.roleId);
    setLoadingCreate(false);
    closeModal();
  };

  return (
    <Formik<CreateRoleUserInput>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Stack spacing="md">
            <FormikSelectInput
              name="roleId"
              label="Role"
              options={
                roles?.map(role => ({ value: role.id, label: role.name })) || []
              }
            />
            <Group position="right">
              <Button
                type="submit"
                color="orange"
                loading={loadingCreate || loadingUpdate}
              >
                {userData ? 'Update' : 'Create'}
              </Button>
              <Button
                type="button"
                variant="outline"
                color="orange"
                onClick={closeModal}
                disabled={loadingCreate || loadingUpdate}
              >
                Cancel
              </Button>
            </Group>
          </Stack>
          {/* DEV TOOLS */}
          {process.env.NODE_ENV === 'development' && (
            <DisplayFormikState {...props} />
          )}
        </form>
      )}
    </Formik>
  );
};
