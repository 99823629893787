// External libraries
import dayjs from 'dayjs';
import { useMemo, createRef } from 'react';
import { Menu, Badge, Group } from '@mantine/core';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
// Internal libraries or utilities
import { modalService } from 'services/modalService';
// API Types
import { Business, Inspection, Template } from 'API';
// Components
import InspectionForm from './InspectionForm';
import { PencilSimple, X, DownloadSimple } from '@phosphor-icons/react';
import { inspectionService } from 'services/inspectionService';
import DefectsCarousel from './DefectsCarousel';
import { bucketURL } from 'config/bucketURL';
import ImagesCarousel from './ImagesCarousel';
import { notificationService } from 'services/notificationService';

type props = {
  inspections: Inspection[] | null;
  templates: Template[] | undefined;
  selected: any;
  setSelected: (selected: any) => void;
};

const handleExport = async (inspection: any) => {
  notificationService.info('Downloading report...', 'Info', {
    autoClose: 2500,
  });
  inspection = {
    ...inspection,
    defects: !inspection.defects
      ? null
      : inspection.defects.map((defect: any) => {
          if (defect) {
            defect.marker &&
              defect.marker[0] &&
              (defect.marker[0] =
                defect.marker[0] !== ''
                  ? (defect.marker[0] as string)
                  : ({} as string));
            if (defect.marker) {
              (defect as any).canvasRef = createRef();
              (defect as any).imgRef = createRef();
            }
          }
          return defect;
        }),
  };
  const canvas = document.createElement('canvas');
  const img = document.createElement('img');
  document.body.appendChild(img);
  img.style.display = 'none';
  canvas.style.display = 'none';
  canvas.width = 350;
  canvas.height = 350;
  if (inspection.siteImages && inspection.siteImages[0]) {
    img.src = `${bucketURL}/${inspection.siteImages[0]}`;
  }
  document.body.appendChild(canvas);
  let canvasContext = canvas.getContext('2d');
  let marker;
  if (
    inspection.defects &&
    inspection.defects[0] &&
    inspection.defects[0].marker
  ) {
    const markerObj = inspection.defects[0].marker[0];
    if (markerObj && markerObj !== '') {
      marker = JSON.parse(markerObj);
    }
  }
  img.crossOrigin = 'anonymous';
  if (!img.src.includes('?not_from_cache_please')) {
    img.src += '?not_from_cache_please';
  }

  if (
    inspection.defects &&
    inspection.defects[0] &&
    inspection.defects[0].imgRef
  ) {
    inspection.defects[0].imgRef.current = img;
  }

  if (
    inspection.defects &&
    inspection.defects[0] &&
    inspection.defects[0].canvasRef
  ) {
    inspection.defects[0].canvasRef.current = canvas;
  }

  let canvasWidth = 350,
    canvasHeight = 350;

  let scale = Math.min(
      canvasWidth / img.naturalWidth,
      canvasHeight / img.naturalHeight,
    ),
    x = canvasWidth / 2 - (img.naturalWidth / 2) * scale,
    y = canvasHeight / 2 - (img.naturalHeight / 2) * scale;

  if (canvasContext) {
    canvasContext.drawImage(
      img,
      x,
      y,
      img.naturalWidth * scale,
      img.naturalHeight * scale,
    );
    canvasContext.fillStyle = 'black';
    canvasContext.font = '50px Arial';
    canvasContext.globalCompositeOperation = 'destination-over';
    canvasContext.fillStyle = '#FFFFFF';
    canvasContext.fillRect(0, 0, canvasWidth, canvasHeight);
    canvasContext.globalCompositeOperation = 'source-over';
    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = '#000000';
    // Show dot
    canvasContext.fillStyle = '#C00000';
    canvasContext.fillRect(marker.left, marker.top, 10, 10);
  }

  await new Promise(resolve => setTimeout(resolve, 400));

  await inspectionService.downloadReport(inspection);
  img.remove();
  canvas.remove();
  notificationService.success('Report downloaded!');
};

function InspectionTable({
  inspections,
  templates,
  selected,
  setSelected,
}: props) {
  const data = useMemo(() => inspections, [inspections]);
  const columns = useMemo<MRT_ColumnDef<Inspection>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Site',
        accessorKey: 'site',
      },
      {
        header: 'Area',
        accessorKey: 'area',
      },
      {
        header: 'Equipment',
        accessorKey: 'equipment',
      },
      {
        header: 'Auditors Note',
        accessorKey: 'auditorsNote',
      },
      {
        header: 'Business owner',
        accessorKey: 'Business',
        Cell: ({ cell }) => {
          return cell.getValue<Business>().name;
        },
      },
      // {
      //   header: 'Defects',
      //   accessorKey: 'defects',
      //   Cell: ({ row }) => {
      //     if (!row?.original?.defects?.length) return null;
      //     return (
      //       <Button
      //         color="orange"
      //         onClick={() => {
      //           modalService.form({
      //             title: 'Defects',
      //             size: 'xl',
      //             children: <DefectsCarousel inspection={row.original} />,
      //             onClose: () => null,
      //           });
      //         }}
      //       >
      //         Show defects
      //       </Button>
      //     );
      //   },
      // },
      // TODO: fix this
      {
        header: 'Due Date',
        accessorKey: 'dueDate',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const dueDate = dayjs.unix(Number(cell.getValue<number>()));
          return dueDate.format('DD/MM/YYYY');
        },
      },
      {
        header: 'Images',
        accessorKey: 'siteImages',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row, cell }) => {
          return (
            <Group position="center">
              {cell.getValue<string[]>()?.map((image, index) => {
                return (
                  <img
                    key={image}
                    src={`${bucketURL}/${image}`}
                    height={36}
                    alt=""
                    style={{ borderRadius: 4, cursor: 'pointer' }}
                    onClick={() => {
                      modalService.form({
                        title: 'Images',
                        size: 'lg',
                        children: row?.original?.defects ? (
                          <DefectsCarousel inspection={row.original} />
                        ) : (
                          <>
                            {row.original.siteImages && (
                              <ImagesCarousel
                                currentIndex={index}
                                images={
                                  row.original.siteImages.filter(
                                    i => i !== null,
                                  ) as string[]
                                }
                              />
                            )}
                          </>
                        ),
                        onClose: () => null,
                      });
                    }}
                  />
                );
              })}
            </Group>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.defects && !row.original.verifiers && (
                <Badge color="red">Pending Verification</Badge>
              )}
              {row.original.defects && row.original.verifiers?.length === 1 && (
                <Badge color="green">Completed</Badge>
              )}
              {!row.original.defects &&
                row.original.dueDate &&
                row.original.dueDate >
                  Math.floor(new Date().getTime() / 1000.0) && (
                  <Badge color="blue">Upcoming</Badge>
                )}
              {!row.original.defects &&
                row.original.dueDate &&
                row.original.dueDate <
                  Math.floor(new Date().getTime() / 1000.0) && (
                  <Badge color="red">Past</Badge>
                )}
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    // enableRowSelection: true,
    enableRowActions: true,
    state: { isLoading: !data },
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          icon={<PencilSimple size={16} />}
          onClick={() => {
            modalService.form({
              title: 'Edit Inspection',
              size: 'xl',
              children: (
                <InspectionForm
                  inspectionData={row.original}
                  closeModal={modalService.close}
                  templates={templates}
                />
              ),
              onClose: () => null,
            });
          }}
        >
          Edit
        </Menu.Item>
        {row.original.defects && (
          <Menu.Item
            icon={<DownloadSimple size={16} />}
            onClick={() => {
              handleExport(row.original);
            }}
          >
            Export
          </Menu.Item>
        )}
        <Menu.Item
          icon={<X size={16} />}
          onClick={() =>
            modalService.delete({
              title: `Delete ${row.original.name}`,
              children: `Are you sure you want to delete ${row.original.name}?`,
              onConfirm: () => inspectionService.remove(row.original.id),
            })
          }
        >
          Delete
        </Menu.Item>
      </>
    ),
  });

  return <MantineReactTable table={table} />;
}

export default InspectionTable;
