import { Global } from '@mantine/core';
import Black from './Gilroy-Black.ttf';
import ExtraBold from './Gilroy-ExtraBold.ttf';
import Regular from './Gilroy-Regular.ttf';
import Medium from './Gilroy-Medium.ttf';
import SemiBold from './Gilroy-SemiBold.ttf';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Gilroy',
            src: `url('${Black}') format("truetype")`,
            fontWeight: 900,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Gilroy',
            src: `url('${ExtraBold}') format("truetype")`,
            fontWeight: 800,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Gilroy',
            src: `url('${Regular}') format("truetype")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Gilroy',
            src: `url('${Medium}') format("truetype")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Gilroy',
            src: `url('${SemiBold}') format("truetype")`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
}
