import {
  Paper,
  createStyles,
  Text,
  Stack,
  Box,
  rem,
  useMantineColorScheme,
  useMantineTheme,
  Group,
  Switch,
} from '@mantine/core';
import { MoonStars, Sun } from '@phosphor-icons/react';

const useStyles = createStyles(theme => ({
  wrapper: {
    minHeight: rem(900),
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1672187493247-52c3084c9785?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1500&q=80)',
  },

  form: {
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: rem(900),
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

type AuthLayoutProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

export function AuthLayout({ children, title }: AuthLayoutProps) {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { classes } = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.form} radius={0} pt={60} px={30}>
        <Stack spacing="md" justify="space-between">
          <Group position="center" mb={50} mt={'md'}>
            {title}
          </Group>
          {children}
        </Stack>
        <Group position="center">
          <Switch
            checked={colorScheme === 'dark'}
            color={'orange'}
            onChange={() => toggleColorScheme()}
            size="sm"
            onLabel={<Sun size="1rem" color={theme.white} />}
            offLabel={<MoonStars size="1rem" color={theme.colors.gray[6]} />}
          />
          <Text fz="xs"> MineCheck © 2023</Text>
        </Group>
      </Paper>
    </Box>
  );
}
