// External libraries
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
// Types
import {
  DeleteBusinessInput,
  UpdateBusinessInput,
  CreateBusinessInput,
} from '../API';
// Utilities
import { clean } from 'utils/Helpers';
// Internal modules and GraphQL mutations
import {
  deleteBusiness as deleteBusinessMutation,
  updateBusiness as updateBusinessMutation,
  createBusiness as createBusinessMutation,
} from '../graphql/mutations';
import { notificationService } from './notificationService';

class BusinessService {
  async remove(id: string) {
    try {
      const response = await API.graphql<GraphQLQuery<DeleteBusinessInput>>(
        graphqlOperation(deleteBusinessMutation, {
          input: { id },
        }),
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async create(values: CreateBusinessInput) {
    try {
      if (!values) {
        throw new Error('No business values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<CreateBusinessInput>>(
        graphqlOperation(createBusinessMutation, { input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Business successfully created.');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async update(id: string, values: UpdateBusinessInput) {
    try {
      if (!id) {
        throw new Error('No business id provided.');
      }
      if (!values) {
        throw new Error('No business values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<UpdateBusinessInput>>(
        graphqlOperation(updateBusinessMutation, { id, input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Business updated');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }
}

export const businessService = new BusinessService();
