import Inspections from 'pages/Inspections';
import Templates from 'pages/Templates';
import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route,
} from 'react-router-dom';
import { PATHS } from './paths';
import { AuthUsers } from 'pages/AuthUsers';
import { Roles } from 'pages/Roles';
import { Login } from 'pages/Login';
import Businesses from 'pages/Businesses';
import { Users } from 'pages/Users';
import { UnprotectedRoute } from './UnprotectedRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { Register } from 'pages/Register';
import PrivacyPolicy from 'pages/Privacy';

export function Routes() {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/login" element={<UnprotectedRoute component={Login} />} />
        <Route
          path="/register"
          element={<UnprotectedRoute component={Register} />}
        />
        <Route
          path={PATHS.privacy}
          element={<UnprotectedRoute component={PrivacyPolicy} />}
        />
        <Route
          path={PATHS.templates}
          element={<ProtectedRoute component={Templates} />}
        />
        <Route
          path={PATHS.inspections}
          element={<ProtectedRoute component={Inspections} />}
        />
        <Route
          path={PATHS.businesses}
          element={<ProtectedRoute component={Businesses} />}
        />
        <Route
          path={PATHS.roles}
          element={<ProtectedRoute component={Roles} />}
        />
        <Route
          path={PATHS.users}
          element={<ProtectedRoute component={Users} />}
        />
        <Route
          path={PATHS.authUsers}
          element={<ProtectedRoute component={AuthUsers} />}
        />
        <Route path={'*'} element={<UnprotectedRoute component={Login} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
}
