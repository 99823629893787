import { Field, FieldProps } from 'formik';
import { PasswordInput } from '@mantine/core';

export const FormikPasswordInput = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps) => (
      <PasswordInput
        placeholder="Password"
        label={label}
        value={field.value || ''}
        onChange={e => form.setFieldValue(name, e.target.value)}
        error={meta.touched && meta.error ? meta.error : undefined}
      />
    )}
  </Field>
);
