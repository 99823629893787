import { useNavigate } from 'react-router';
import { Menu, Badge } from '@mantine/core';
import { useMemo } from 'react';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { PATHS } from 'routes/paths';

type AttributeName = 'sub' | 'email_verified' | 'email';

type TransformedAttributes = {
  sub: string;
  email_verified: string;
  email: string;
};

type UserAttribute = {
  Name: AttributeName;
  Value: string;
};

type UserWithTransformedAttributes = User & TransformedAttributes;

export type User = {
  Attributes: UserAttribute[];
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
  name: string;
};

function transformAttributes(
  attributes: UserAttribute[],
): Record<AttributeName, string> {
  const result: Record<AttributeName, string> = {} as any;
  attributes.forEach(attr => {
    result[attr.Name] = attr.Value;
  });
  return result;
}

export function AuthUsersTable({ users }: { users: User[] }) {
  const navigate = useNavigate();
  const data = useMemo(
    () =>
      users?.map(user => ({
        ...user,
        ...transformAttributes(user.Attributes),
      })),
    [users],
  );
  const columns = useMemo<MRT_ColumnDef<UserWithTransformedAttributes>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'userID',
        accessorKey: 'Username',
      },
      {
        header: 'Email verified',
        accessorKey: 'email_verified',
      },
      {
        header: 'Enabled',
        accessorKey: 'Enabled',
        Cell: ({ cell }) => (
          <Badge color={cell.getValue() ? 'green' : 'red'}>
            {cell.getValue() ? 'Enabled' : 'Disabled'}
          </Badge>
        ),
      },
      {
        header: 'Status',
        accessorKey: 'UserStatus',
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableRowActions: true,
    state: { isLoading: !data },
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          onClick={() =>
            navigate(PATHS.createInspection(row.original.Username))
          }
        >
          Activate
        </Menu.Item>
        <Menu.Item onClick={() => console.info('Delete')}>Deactivate</Menu.Item>
      </>
    ),
  });

  return <MantineReactTable table={table} />;
}
