// External libraries
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
// Types
import {
  DeleteTemplateInput,
  UpdateTemplateInput,
  CreateTemplateInput,
} from '../API';
// Utilities
import { clean } from 'utils/Helpers';
// Internal modules and GraphQL mutations
import {
  deleteTemplate as deleteTemplateMutation,
  updateTemplate as updateTemplateMutation,
  createTemplate as createTemplateMutation,
} from '../graphql/mutations';
import { notificationService } from './notificationService';

class TemplateService {
  async remove(id: string) {
    try {
      const response = await API.graphql<GraphQLQuery<DeleteTemplateInput>>(
        graphqlOperation(deleteTemplateMutation, {
          input: { id },
        }),
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async create({ ...values }: CreateTemplateInput) {
    try {
      if (!values) {
        throw new Error('No template values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<CreateTemplateInput>>(
        graphqlOperation(createTemplateMutation, { input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Template successfully created.');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }

  async update({ id, name, fields }: UpdateTemplateInput) {
    try {
      if (!id) {
        throw new Error('No template id provided.');
      }
      if (!name || !fields) {
        throw new Error('No template values provided.');
      }
      let payload = { ...clean({ name, fields }, true) };
      console.log(id, payload);
      const response = await API.graphql<GraphQLQuery<UpdateTemplateInput>>(
        graphqlOperation(updateTemplateMutation, {
          id: id,
          input: { id, ...payload },
        }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Template updated');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      let errorMessage = '';

      if (
        error?.errors &&
        Array.isArray(error.errors) &&
        error.errors.length > 0
      ) {
        errorMessage = error.errors[0].message || 'An unknown error occurred';
      } else {
        errorMessage = 'An unknown error occurred';
      }

      console.log(errorMessage);
      notificationService.error(errorMessage);
      return { ok: false, error: errorMessage };
    }
  }
}

export const templateService = new TemplateService();
