export const PATHS = {
  templates: '/templates',
  createTemplate: (id?: string) => `/create-template${id ? `/${id}` : ''}`,
  inspections: '/inspections',
  createInspection: (id?: string) => `/create-inspection${id ? `/${id}` : ''}`,
  inspector: (id: string) => `/inspector/${id}`,
  businesses: '/businesses',
  roles: '/roles',
  authUsers: '/auth-users',
  login: '/login',
  register: '/register',
  users: '/users',
  privacy: '/privacy',
};
