// React and other external libraries
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button, Group, Stack } from '@mantine/core';
// Styles
import 'react-medium-image-zoom/dist/styles.css';
// Internal modules and components
import { Role } from '../../API';
import { roleService } from '../../services/roleService';
import { FormikTextInput } from 'components/forms/ForikTextInput';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';

interface RoleFormProps {
  roleData?: Role;
  closeModal: () => void;
}

type FormValues = {
  name: string;
  description: string;
  permissions: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().min(5).max(25).required('Required'),
  description: Yup.string().min(5).max(50).required('Required'),
  permissions: Yup.string().required('Required'),
});

export const RoleForm = ({ roleData, closeModal }: RoleFormProps) => {
  const [initialValues] = useState<FormValues>({
    name: roleData?.name || '',
    description: roleData?.description || '',
    permissions: roleData?.permissions?.join(', ') || '',
  });

  const handleSubmit = async (values: FormValues) => {
    const permissions = values.permissions?.split(',').map(p => p.trim());
    if (roleData?.id) {
      const updateInput = { ...values, id: roleData?.id, permissions };
      const response = await roleService.update(updateInput);
      if (response.ok) closeModal();
    } else {
      const createInput = { ...values, permissions };
      const response = await roleService.create(createInput);
      if (response.ok) closeModal();
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize={true} // This is needed to update the form when initialValues changes
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
      validationSchema={validationSchema}
    >
      {props => {
        const { dirty, isValid, isSubmitting, handleSubmit } = props;
        return (
          <form>
            <Stack>
              {/* NAME */}
              <FormikTextInput name={'name'} label={'Name'} />
              <FormikTextInput name={'description'} label={'Description'} />
              <FormikTextInput name={'permissions'} label={'Permissions'} />
            </Stack>
            {/* DEV TOOLS */}
            {process.env.NODE_ENV === 'development' && (
              <DisplayFormikState {...props} />
            )}
            {/* ACTION BUTTONS */}
            <Group position="right" spacing={16}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
                color="orange"
                loading={isSubmitting}
                disabled={!isValid || !dirty}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button variant="outline" onClick={closeModal} color="orange">
                Cancel
              </Button>
            </Group>
          </form>
        );
      }}
    </Formik>
  );
};
