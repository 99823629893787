// React and other external libraries
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, Group, Stack } from '@mantine/core';
// Styles
import 'react-medium-image-zoom/dist/styles.css';
// Internal modules and components
import { InspectionFieldInput, Template } from '../../API';
import { templateService } from '../../services/templateService';
import { FormikTextInput } from 'components/forms/ForikTextInput';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { FormikDragAndDropFieldArray } from 'components/forms/FormikDragAndDropFieldArray';
import { useAuthContext } from 'contexts/UserContext';
import { IdentificationBadge } from '@phosphor-icons/react';
import { string } from 'validations';

interface TemplateFormProps {
  templateData?: Template;
  closeModal: () => void;
}

interface TemplateFormValues {
  name: string;
  fields: (InspectionFieldInput | null)[];
}

const validationSchema = Yup.object().shape({
  name: string,
  fields: Yup.array()
    .of(
      Yup.object().shape({
        label: string,
        value: Yup.string(), // optional
        type: string,
      }),
    )
    .nullable(),
});

export const TemplateForm = ({
  templateData,
  closeModal,
}: TemplateFormProps) => {
  const { user, authBusiness } = useAuthContext();

  const fieldsWithoutTypename = (templateData?.fields || []).map(field => {
    if (field?.__typename) {
      const { __typename, ...rest } = field;
      return rest;
    } else {
      return field;
    }
  });

  const [initialValues] = useState<TemplateFormValues>({
    name: templateData?.name || '',
    fields: fieldsWithoutTypename || [],
  });

  const handleSubmit = async ({ name, fields }: TemplateFormValues) => {
    if (templateData?.id) {
      const response = await templateService.update({
        id: templateData.id,
        name,
        fields,
      });
      if (response.ok) closeModal();
    } else {
      const response = await templateService.create({
        name,
        fields,
        templateUserId: user?.id || 'unknown_user',
        businessID: authBusiness?.[0] || 'unknown_business',
      });
      if (response.ok) closeModal();
    }
  };

  return (
    <Formik<TemplateFormValues>
      enableReinitialize={true} // This is needed to update the form when initialValues changes
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
      validationSchema={validationSchema}
    >
      {props => {
        const { dirty, isValid, isSubmitting, handleSubmit } = props;
        return (
          <form>
            <Stack>
              {/* NAME */}
              <FormikTextInput
                name={'name'}
                label={'Name'}
                placeholder="Enter name"
                icon={<IdentificationBadge />}
              />
              <Box>
                {/* FIELD OPTIONS */}
                <FormikDragAndDropFieldArray type="create" />
              </Box>
            </Stack>
            {/* DEV TOOLS */}
            {process.env.NODE_ENV === 'development' && (
              <DisplayFormikState {...props} />
            )}
            {/* ACTION BUTTONS */}
            <Group position="right" spacing={16}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
                color="orange"
                loading={isSubmitting}
                disabled={!isValid || !dirty}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button variant="outline" onClick={closeModal} color="orange">
                Cancel
              </Button>
            </Group>
          </form>
        );
      }}
    </Formik>
  );
};
