import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Box, Group } from '@mantine/core';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { FormikTextInput } from 'components/forms/ForikTextInput';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { FormikPasswordInput } from 'components/forms/FormikPasswordInput';
import { VerifyForm } from './VerifyForm';
import { notificationService } from 'services/notificationService';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import { Password } from '@phosphor-icons/react';

type RegisterFormProps = {
  email: string;
  password: string;
  name: string;
};

export const RegisterForm = () => {
  const [verify, setVerify] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).max(25).required('Name is required'),
    email: Yup.string().email().required(),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const handleSubmit = async (
    { email, password, name }: RegisterFormProps,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setEmail(email);
      setSubmitting(false);
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      setVerify(true);
      notificationService.success('Please check your email for verification');
    } catch (error: any) {
      setSubmitting(false);
      notificationService.error(error.message);
      console.log(error);
    }
  };

  return (
    <Formik<RegisterFormProps>
      initialValues={{ email: '', password: '', name: '' }}
      validationSchema={validationSchema}
      onSubmit={({ email, password, name }, { setSubmitting }) =>
        handleSubmit({ email, password, name }, setSubmitting)
      }
    >
      {props => {
        const { isSubmitting, isValid } = props;
        return (
          <>
            {verify ? (
              <VerifyForm
                email={email}
                onVerificationSuccess={() => {
                  navigate(PATHS.inspections);
                  setVerify(false);
                }}
              />
            ) : (
              <Form>
                <Box>
                  <FormikTextInput
                    label="Name"
                    name="name"
                    placeholder="John Doe"
                  />
                </Box>

                <Box>
                  <FormikTextInput
                    label="Email address"
                    name="email"
                    placeholder="hello@gmail.com"
                  />
                </Box>
                <Box>
                  <FormikPasswordInput label="Password" name="password" />
                </Box>
                <Button
                  type="submit"
                  color="orange"
                  fullWidth
                  mt="xl"
                  size="md"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Register
                </Button>
                <Group position="center">
                  <Button
                    mt={18}
                    leftIcon={<Password size={18} />}
                    variant="ghost"
                    onClick={() => setVerify(true)}
                  >
                    Verify OTP
                  </Button>
                </Group>
                {/* DEV TOOLS */}
                {process.env.NODE_ENV === 'development' && (
                  <DisplayFormikState {...props} />
                )}
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};
