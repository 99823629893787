import { Field, FieldProps } from 'formik';
import { Select } from '@mantine/core';

import React from 'react';

interface FormikSelectInputProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  clearable?: boolean;
}

export const FormikSelectInput: React.FC<FormikSelectInputProps> = ({
  name,
  label,
  options,
  clearable = false,
}) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps) => (
      <Select
        {...field}
        value={field.value || ''}
        onChange={value => form.setFieldValue(name, value)}
        placeholder={`Select ${label.toLowerCase()}`}
        label={label}
        data={options}
        error={meta.touched && meta.error ? meta.error : undefined}
        clearable={clearable}
      />
    )}
  </Field>
);
