// https://docs.amplify.aws/cli/auth/admin/
// addUserToGroup: Adds a user to a specific Group. Expects username and groupname in the POST body.
// removeUserFromGroup: Removes a user from a specific Group. Expects username and groupname in the POST body.
// confirmUserSignUp: Confirms a users signup. Expects username in the POST body.
// disableUser: Disables a user. Expects username in the POST body.
// enableUser: Enables a user. Expects username in the POST body.
// getUser: Gets specific user details. Expects username as a GET query string.
// listUsers: Lists all users in the current Cognito User Pool. You can provide an OPTIONAL limit (between 0 and 60) as a GET query string, which returns a NextToken that can be provided as a token query string for pagination.
// listGroups: Lists all groups in the current Cognito User Pool. You can provide an OPTIONAL limit (between 0 and 60) as a GET query string, which returns a NextToken that can be provided as a token query string for pagination.
// listGroupsForUser: Lists groups to which current user belongs to. Expects username as a GET query string. You can provide an OPTIONAL limit (between 0 and 60) as a GET query string, which returns a NextToken that can be provided as a token query string for pagination.
// listUsersInGroup: Lists users that belong to a specific group. Expects groupname as a GET query string. You can provide an OPTIONAL limit (between 0 and 60) as a GET query string, which returns a NextToken that can be provided as a token query string for pagination.
// signUserOut: Signs a user out from User Pools, but only if the call is originating from that user. Expects username in the POST body.
//
//
//
import { useEffect, useState } from 'react';
// import { Auth, API } from 'aws-amplify';
// import { Badge, Button, Col } from 'react-bootstrap';
import { adminService } from 'services/adminService';
import { AuthUsersTable } from 'components/authUsers/AuthUsersTable';

export function AuthUsers() {
  const [users, setUsers] = useState([]);
  // const apiName = 'AdminQueries';
  // const [doing, setDoing] = useState(false);

  useEffect(() => {
    adminService.listUsers(50).then(users => {
      setUsers(users);
    });
  }, []);

  // const activeInactive = async (ac: any, username: any) => {
  //   /**
  //    * 1.   If active ac === 'a'
  //    *      1.  confirmUserSignUp
  //    *      2.  enableUser
  //    *      3.  addUserToGroup = admin (In future, allow user to decide which group to add to)
  //    * 2.   if inactive ac === 'n'
  //    *      1.  disableUser
  //    *      2.  signUserOut
  //    */
  //   if (ac === 'a') {
  //     setDoing(true);
  //     // 1.1.
  //     try {
  //       await API.get(apiName, '/confirmUserSignUp', {
  //         queryStringParameters: {
  //           username: username,
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `${(await Auth.currentSession())
  //             .getAccessToken()
  //             .getJwtToken()}`,
  //         },
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     // 1.2.
  //     try {
  //       await API.get(apiName, '/enableUser', {
  //         queryStringParameters: {
  //           username: username,
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `${(await Auth.currentSession())
  //             .getAccessToken()
  //             .getJwtToken()}`,
  //         },
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     // 1.3.
  //     try {
  //       await API.get(apiName, '/addUserToGroup', {
  //         queryStringParameters: {
  //           username: username,
  //           groupname: 'admin',
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `${(await Auth.currentSession())
  //             .getAccessToken()
  //             .getJwtToken()}`,
  //         },
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     setDoing(false);
  //     window.location.reload();
  //   } else if (ac === 'n') {
  //     setDoing(true);
  //     // 2.1.
  //     try {
  //       await API.get(apiName, '/disableUser', {
  //         queryStringParameters: {
  //           username: username,
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `${(await Auth.currentSession())
  //             .getAccessToken()
  //             .getJwtToken()}`,
  //         },
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     // 2.2.
  //     try {
  //       await API.get(apiName, '/signUserOut', {
  //         queryStringParameters: {
  //           username: username,
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `${(await Auth.currentSession())
  //             .getAccessToken()
  //             .getJwtToken()}`,
  //         },
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     setDoing(false);
  //     window.location.reload();
  //   }
  // };

  return <AuthUsersTable users={users} />;
}
