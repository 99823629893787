// Routes.tsx
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppShell, useMantineTheme } from '@mantine/core';
import { useAuthContext } from 'contexts/UserContext';
import { NavHeader } from 'layout/nav/NavHeader';
import { Navbar } from 'layout/nav/Navbar';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useAuthContext();
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbar={<Navbar opened={opened} />}
      header={<NavHeader opened={opened} setOpened={setOpened} />}
    >
      <Component {...rest} />
    </AppShell>
  );
};
