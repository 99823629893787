import { API, graphqlOperation } from 'aws-amplify';
import {
  CreateInspectionInput,
  UpdateInspectionInput,
  DeleteInspectionInput,
} from 'API';
import { clean, blobToBase64, hqDefectLocation } from 'utils/Helpers';
import {
  deleteInspection as DeleteInspectionMutation,
  updateInspection as UpdateInspectionMutation,
  createInspection as CreateInspectionMutation,
} from 'graphql/mutations';
import { GraphQLQuery } from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
import { notificationService } from './notificationService';
// External libraries
import moment from 'moment-timezone';
import _ from 'lodash';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
// Report images
import vc1Img from 'assets/images/vc1.png';
import vc2Img from 'assets/images/vc2.png';
import vc3Img from 'assets/images/vc3.png';
import vc4Img from 'assets/images/vc4.png';
import vc5Img from 'assets/images/vc5.png';
import vc6Img from 'assets/images/vc6.png';
import vc7Img from 'assets/images/vc7.png';
import vc8Img from 'assets/images/vc8.png';
import vc9Img from 'assets/images/vc9.png';
import logo from 'assets/images/logo.png';

class InspectionService {
  async remove(id: string) {
    try {
      if (!id) {
        throw new Error('No inspection id provided.');
      }
      const response = await API.graphql<GraphQLQuery<DeleteInspectionInput>>(
        graphqlOperation(DeleteInspectionMutation, {
          input: { id },
        }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Inspection successfully deleted.');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async create(values: CreateInspectionInput) {
    try {
      if (!values) {
        throw new Error('No inspection values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<CreateInspectionInput>>(
        graphqlOperation(CreateInspectionMutation, { input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Inspection successfully created!');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async update(id: string, values: UpdateInspectionInput) {
    try {
      if (!id) {
        throw new Error('No inspection id provided.');
      }
      if (!values) {
        throw new Error('No inspection values provided.');
      }
      let payload = { ...clean(values, true) };
      const response = await API.graphql<GraphQLQuery<UpdateInspectionInput>>(
        graphqlOperation(UpdateInspectionMutation, { input: payload }),
      );

      if (response.errors) {
        throw new Error(response.errors[0].message);
      } else {
        notificationService.success('Inspection successfully updated!');
        return { ok: true, data: response.data };
      }
    } catch (error: any) {
      notificationService.error(error.message);
      return { ok: false, error: error.message };
    }
  }

  async downloadReport(values: UpdateInspectionInput) {
    try {
      let fileName = `${_.kebabCase(values.name || '')}-${_.kebabCase(
        values.site || '',
      )}-${_.kebabCase(values.area || '')}-${moment()
        .tz('Pacific/Auckland')
        .format('D/M/Y')}`;
      let excelName = `${_.startCase(values.name || '')} ${_.startCase(
        values.site || '',
      )} ${_.startCase(values.area || '')} ${moment()
        .tz('Pacific/Auckland')
        .format('D-M-Y')}`;
      let imageMap: any[] = [];
      const zip = new JSZip();

      const workbook = new Excel.Workbook();
      const titlePage = workbook.addWorksheet('Title Page', {
        views: [{ showGridLines: false }],
      });
      const summaryOfEntries = workbook.addWorksheet('Summary of entries');
      const visualRating = workbook.addWorksheet(
        'Visual Condition Rating Ref',
        { views: [{ showGridLines: false }] },
      );
      // VC1
      const rws = [3, 5, 7, 9, 11, 13, 15, 17, 19];
      //
      //
      //
      for (let i = 0; i < rws.length; i++) {
        const r = rws[i];
        visualRating.getCell(`B${r}`).value =
          i === 0
            ? 'Generic Condition Rating Scale'
            : i === 1
            ? 'Concrete Pile'
            : i === 2
            ? 'Concrete Beams'
            : i === 3
            ? 'Concrete Deck Soffit'
            : i === 4
            ? 'Concrete Deck Top'
            : i === 5
            ? 'Steel/Coated Steel'
            : i === 6
            ? 'Steel Walkway'
            : i === 7
            ? 'Timber'
            : i === 8
            ? 'Crane Rail'
            : '';

        visualRating.addImage(
          workbook.addImage({
            base64: (await blobToBase64(
              await (
                await fetch(
                  i === 0
                    ? vc1Img
                    : i === 1
                    ? vc2Img
                    : i === 2
                    ? vc3Img
                    : i === 3
                    ? vc4Img
                    : i === 4
                    ? vc5Img
                    : i === 5
                    ? vc6Img
                    : i === 6
                    ? vc7Img
                    : i === 7
                    ? vc8Img
                    : i === 8
                    ? vc9Img
                    : vc1Img,
                )
              ).blob(),
            )) as string,
            extension: 'png',
          }),
          {
            tl: { col: 2.2, row: r - 1 + 0.2 },
            ext: { height: 482, width: i === 0 ? 340 : 868 },
          },
        );
        // 0.6 * img width
        visualRating.getRow(r).height = 368;
        visualRating.getCell(`B${r}`).border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
        };
        visualRating.getCell(`C${r}`).border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
        };
      }
      visualRating.getColumn('B').font = {
        size: 12,
        name: 'Calibri',
        family: 2,
        bold: true,
      };
      visualRating.getColumn('B').alignment = {
        textRotation: 90,
        vertical: 'middle',
        horizontal: 'center',
      };
      visualRating.getColumn('C').width = 130;

      /**
       * Title Page
       */
      // Logo
      titlePage.mergeCells('A1:C1');
      titlePage.addImage(
        workbook.addImage({
          base64: (await blobToBase64(
            await (await fetch(logo)).blob(),
          )) as string,
          extension: 'png',
        }),
        {
          tl: { col: 0.5, row: 0.5 },
          ext: { height: 60, width: 328 },
        },
      );
      // Title
      titlePage.mergeCells('A2:C3');
      let cell = titlePage.getCell('A2');
      cell.value = 'Asset Integrity Inspection Report';
      cell.font = { size: 20, name: 'Calibri', family: 2, bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      // Text fields
      titlePage.getCell('A4').value = 'Summary Comments';
      titlePage.getCell('B4').value = values?.auditorsNote || '';
      titlePage.getCell('A12').value = 'Current revision';
      titlePage.mergeCells('A12:B12');
      titlePage.getCell('A6').value = 'Rev';
      titlePage.getCell('B6').value = 'Date';
      titlePage.getCell('C6').value = 'Author';
      titlePage.getRow(12).font = { bold: true };
      titlePage.getRow(6).font = { bold: true };
      titlePage.getRow(4).font = { bold: true };
      titlePage.getRow(4).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      };
      titlePage.mergeCells('B4:C4');

      titlePage.getCell('D1').value = 'Area';
      titlePage.getCell('D2').value = 'Asset Numbers';
      titlePage.getCell('D3').value = 'Date of Inspection';
      titlePage.getCell('D4').value = 'Inspector Name(s)';

      titlePage.getCell('E1').value = values.area;
      if (values?.fields) {
        try {
          titlePage.getCell('E2').value = values.fields.filter(
            f => f?.label === 'Asset Number',
          )[0]?.value;
        } catch (e) {
          titlePage.getCell('E2').value = '';
        }
      }
      if (values?.dueDate) {
        titlePage.getCell('E3').value = new Date(
          values.dueDate * 1000,
        ).toLocaleDateString();
      }
      titlePage.getCell('E4').value = '';

      titlePage.getColumn('D').width = 20;
      titlePage.getColumn('D').alignment = {
        vertical: 'middle',
        horizontal: 'left',
      };
      titlePage.getColumn('E').alignment = {
        vertical: 'middle',
        horizontal: 'left',
      };
      titlePage.getColumn('D').font = {
        name: 'Calibri',
        family: 2,
        bold: true,
      };

      titlePage.getColumn('A').width = 20;
      titlePage.getColumn('B').width = 20;
      titlePage.getColumn('C').width = 20;
      titlePage.getColumn('E').width = 80;
      titlePage.getRow(1).height = 75;
      titlePage.getRow(2).height = 35;
      titlePage.getRow(3).height = 35;
      titlePage.getRow(4).height = 75;

      // Borders
      titlePage.getCell('C1').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('C4').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('D1').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('D2').border = {
        left: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('D3').border = {
        left: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('D4').border = {
        left: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('E1').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('E2').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('E3').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('E4').border = {
        right: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('A3').border = {
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('B3').border = {
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('C3').border = {
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('B4').border = {
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('C4').border = {
        bottom: { style: 'thin', color: { argb: '00000000' } },
      };
      titlePage.getCell('A4').border = {
        bottom: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
      };
      // Revisions borders
      const cols = ['A', 'B', 'C'];
      const rows = ['6', '7', '8', '9', '10', '11', '12'];
      cols.forEach(col => {
        rows.forEach(row => {
          titlePage.getCell(`${col}${row}`).border =
            row !== '12'
              ? {
                  top: { style: 'thin', color: { argb: '00000000' } },
                  right: { style: 'thin', color: { argb: '00000000' } },
                }
              : {
                  top: { style: 'thin', color: { argb: '00000000' } },
                  right: { style: 'thin', color: { argb: '00000000' } },
                  bottom: { style: 'thin', color: { argb: '00000000' } },
                };
        });
      });

      /**
       * Summary of entries
       */
      summaryOfEntries.columns = [
        ...[{ header: '#', width: 5 }],
        ...(values?.fields?.map(f => {
          return {
            header: f?.label,
            width: 30,
          };
        }) as any),
        ...[{ header: 'Defect location', width: 30 }],
      ];
      if (values.defects) {
        for (let i = 0; i < values.defects.length; i++) {
          let defect = values.defects[i],
            row = summaryOfEntries.getRow(i + 2),
            defectValues = defect?.values;
          // # value
          row.getCell(1).value = i + 1;

          if (defectValues && values.fields) {
            for (let j = 0; j < defectValues.length; j++) {
              let field = values.fields[j],
                value = defectValues[j],
                cell = row.getCell(1 + j + 1);

              if (value.toLowerCase().includes('.jpg' || '.png' || '.jpeg')) {
                let imgPayload = {
                  image: values.siteImages ? values.siteImages[j] : null,
                  newName: `defect-${i}-${_.kebabCase(
                    field?.label as string,
                  )}.jpg`,
                  defect: i,
                  picLocation: field?.label,
                };

                if (imgPayload.image) {
                  // Pushing Camera Images
                  imageMap.push(imgPayload);
                  // Adding to workbook
                  summaryOfEntries.addImage(
                    workbook.addImage({
                      base64: imgPayload.image,
                      extension: 'jpeg',
                    }),
                    {
                      tl: {
                        col: Number(cell.col) - 1,
                        row: Number(cell.row) - 1,
                      },
                      ext: { height: 100, width: 75 },
                      hyperlinks: {
                        tooltip: `images/defect-${i}-${_.kebabCase(
                          field?.label as string,
                        )}.jpg`,
                        hyperlink: `images/defect-${i}-${_.kebabCase(
                          field?.label as string,
                        )}.jpg`,
                      },
                    },
                  );
                  summaryOfEntries.getColumn(j + 2).width = 13;
                  row.height = 120;
                } else {
                  cell.value = `defect-${i}-${_.kebabCase(
                    field?.label as string,
                  )}.jpg`;
                }
              } else {
                cell.value = value;
              }

              // Red/Green cells
              if (
                _.lowerCase(field?.label as string) === 'safety issue' ||
                _.lowerCase(field?.label as string) === 'compliance issue'
              ) {
                if (_.lowerCase(value) === 'yes') {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C00000' },
                  };
                  cell.font = { color: { argb: 'FFFFFFFF' } };
                }

                i === 0 && (summaryOfEntries.getColumn(j + 2).width = 15);
              }
            }
          }
          // Defect location
          try {
            let defectImgPayload: any = {
              image: {
                data_url_2:
                  defect &&
                  (defect as any).canvasRef &&
                  defect &&
                  (defect as any).canvasRef.current
                    ? (defect as any).canvasRef.current.toDataURL('image/png')
                    : null,
                data_url:
                  defect && defect.marker && defect.marker[0]
                    ? hqDefectLocation(defect as any)
                    : 'https://media.istockphoto.com/photos/black-curtain-background-scene-picture-id699856648?b=1&k=20&m=699856648&s=170667a&w=0&h=aCoecsMO0d1CQT50tWhhLTRrXl4C9iHveZIo8rPmfp0=',
              },
              newName: `defect-${i}-marker`,
              defect: i,
              picLocation: 'marker',
            };
            imageMap.push(defectImgPayload);
            //  Inserting
            // Adding to workbook
            if (
              defectValues &&
              defectValues.length > 0 &&
              defectImgPayload.image.data_url_2
            ) {
              summaryOfEntries.addImage(
                workbook.addImage({
                  base64: defectImgPayload.image.data_url_2,
                  extension: 'png',
                }),
                {
                  tl: { col: defectValues.length + 1, row: i + 1 },
                  ext: { height: 108, width: 144 },
                  hyperlinks: {
                    tooltip: `images/defect-${i}-marker.png`,
                    hyperlink: `images/defect-${i}-marker.png`,
                  },
                },
              );
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
      // Row Styles
      const row = summaryOfEntries.getRow(1);
      row.alignment = { vertical: 'middle' };
      row.height = 20;
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCCCCC' },
      };
      row.font = { color: { argb: 'FF000000' } };

      const buffer = await workbook.xlsx.writeBuffer();
      zip.file(`${excelName}.xlsx`, buffer, { binary: true });

      // Downloading images to disk
      for (let i = 0; i < imageMap.length; i++) {
        const img = imageMap[i];
        if (img.image.data_url) {
          zip.file(
            `images/${img.newName}.png`,
            img.image.data_url.split(',')[1],
            { base64: true },
          );
        }
        // Sleeping to confirm download
      }
      saveAs(await zip.generateAsync({ type: 'blob' }), `${fileName}.zip`);
    } catch (e) {
      console.log(e);
    }
  }
}

export const inspectionService = new InspectionService();
