// External libraries
import { useState } from 'react';
import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Flex, Group, Stack } from '@mantine/core';
import { Plus } from '@phosphor-icons/react';
import { GraphQLQuery } from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
// Internal libraries or utilities
import { modalService } from 'services/modalService';
// Components
import InspectionTable from '../components/inspections/InspectionTable';
import InspectionForm from 'components/inspections/InspectionForm';
// Constants
// GraphQL queries and mutations
import { getInspection, listInspections } from '../graphql/queries';
import { createInspection } from '../graphql/mutations';
// API Types
import { CreateInspectionInput, GetInspectionQuery, Inspection } from '../API';
import {
  onCreateInspection,
  onUpdateInspection,
  onDeleteInspection,
} from 'graphql/subscriptions';
import { useGraphQLSubscription } from 'hooks/useGraphQLSubscription';
import { useAuthContext } from 'contexts/UserContext';
import { listTemplates } from 'graphql/queries';
import {
  onCreateTemplate,
  onDeleteTemplate,
  onUpdateTemplate,
} from 'graphql/subscriptions';
import { Template } from 'API';

// type props = {
// fetchInspections: () => void;
// inspections: Inspection[] | null | undefined;
// };

export default function Inspections() {
  const { authBusiness } = useAuthContext();
  // const { data: allInspections, isLoading } = useGraphQLQuery<Inspection[]>(
  //   graphqlService.listInspections(),
  // );
  const { data: inspectionsData } = useGraphQLSubscription<Inspection>({
    list: listInspections,
    listKey: 'listInspections',
    onCreate: onCreateInspection,
    onCreateKey: 'onCreateInspection',
    onUpdate: onUpdateInspection,
    onUpdateKey: 'onUpdateInspection',
    onDelete: onDeleteInspection,
    onDeleteKey: 'onDeleteInspection',
    variables: {
      filter: {
        or: authBusiness
          ? authBusiness?.map(business => ({
              businessID: {
                eq: business,
              },
            }))
          : {
              businessID: {
                eq: 'NOT_AUTHORIZED',
              },
            },
      },
    },
  });

  const { data: templatesData } = useGraphQLSubscription<Template>({
    list: listTemplates,
    listKey: 'listTemplates',
    onCreate: onCreateTemplate,
    onCreateKey: 'onCreateTemplate',
    onUpdate: onUpdateTemplate,
    onUpdateKey: 'onUpdateTemplate',
    onDelete: onDeleteTemplate,
    onDeleteKey: 'onDeleteTemplate',
    variables: {
      filter: {
        or: authBusiness
          ? authBusiness?.map(business => ({
              businessID: {
                eq: business,
              },
            }))
          : {
              businessID: {
                eq: 'NOT_AUTHORIZED',
              },
            },
      },
    },
  });

  console.log('templatesData', templatesData);

  // TO GET ONE INSPECTION
  // const { data: oneInspection } = useGraphQLQuery<Inspection>(
  //   graphqlService.getInspection('d735bd01-2cd2-4340-808b-a6acd7eea2b7'),
  // );

  // Form Modal
  const [selected, setSelected] = useState([]);

  // const autoComplete = (text: string) => {
  //   console.log(text);
  //   setVisibleInspections(
  //     inspections?.filter(inspection =>
  //       JSON.stringify(inspection)
  //         .toLocaleLowerCase()
  //         .includes(text.toLowerCase()),
  //     ) || [],
  //   );
  // };

  const mergeInspections = async () => {
    let oldInspections: Inspection[] = [];
    for (let i = 0; i < selected?.length; i++) {
      const j = selected[i];

      try {
        const response = await API.graphql<GraphQLQuery<GetInspectionQuery>>(
          graphqlOperation(getInspection, {
            id: j,
          }),
        );
        if (response.errors) {
          console.error(response.errors);
        } else if (response.data?.getInspection) {
          oldInspections.push(response.data?.getInspection as Inspection);
        }
      } catch (e) {}
    }

    const maxDueDate = _.max(oldInspections.map((o, k) => o.dueDate));
    let g: CreateInspectionInput = {
      name: `[Merged] ${oldInspections.map((o, k) => o.name).join(' / ')}`,
      area: oldInspections.map((o, k) => o.area).join(' / '),
      site: oldInspections.map((o, k) => o.site).join(' / '),
      siteImages: [],
      equipment: oldInspections.map((o, k) => o.equipment).join(' / '),
      fields: [],
      auditorsNote: oldInspections.map((o, k) => o.auditorsNote).join('. '),
      defects: [],
      dueDate: maxDueDate !== undefined ? maxDueDate : 0,
      businessID: oldInspections[0].businessID,
    };

    for (let i = 0; i < oldInspections?.length; i++) {
      const o = oldInspections[i];
      /**
       * 1.   Merge site Images
       * 2.   Merge Fields & Unique
       * 3.   Merge defects
       *          In each defect, update img index
       */
      // Unique fields
      const uniqueKeys = new Set<string>();

      for (let i = 0; i < oldInspections?.length; i++) {
        const o = oldInspections[i];
        if (o.fields) {
          for (let f = 0; f < o.fields?.length; f++) {
            const field = o.fields[f];
            if (field) {
              const key = [field.type, field.label, field.value].join('#');
              if (!uniqueKeys.has(key)) {
                uniqueKeys.add(key);
                g.fields?.push(field); // Assuming g.fields is an array
              }
            }
          }
        }
      }

      // defects
      if (!o.defects) {
        continue;
      } else {
        for (let d = 0; d < o.defects?.length; d++) {
          const defect = o.defects[d];
          if (defect?.marker && g.defects) {
            g.defects.push({
              ...defect,
              ...{
                marker: [
                  defect.marker[0] &&
                  defect.marker[0] !== '' &&
                  defect.marker[0] !== 'null'
                    ? JSON.stringify({
                        ...JSON.parse(defect.marker[0]),
                        ...{
                          img:
                            JSON.parse(defect.marker[0]).img +
                            (g.siteImages || []).length,
                        },
                      })
                    : defect.marker[0],
                ],
              },
            });
          }
        }
        if (g.siteImages && o.siteImages) {
          g.siteImages = [...g.siteImages, ...(o.siteImages || [])];
        }
      }
    }

    g.fields = _.values(g.fields);

    try {
      const d = await API.graphql(
        graphqlOperation(createInspection, {
          input: g,
        }),
      );
      console.log(d);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack>
      {/* CREATE */}
      <Group position="right">
        <Button
          leftIcon={<Plus size={18} />}
          color="orange"
          onClick={() => {
            modalService.form({
              title: 'Create Inspection',
              size: 'xl',
              children: (
                <InspectionForm
                  closeModal={modalService.close}
                  templates={templatesData ?? []}
                />
              ),
              onClose: () => null,
            });
          }}
        >
          Create
        </Button>
      </Group>
      {/* MERGE */}
      {selected?.length > 1 && (
        <Flex direction="row" justify="end">
          <Button size="small" variant="primary" onClick={mergeInspections}>
            Merge Inspections
          </Button>
        </Flex>
      )}
      {/* TABLE */}
      {authBusiness && authBusiness?.length > 0 && (
        <InspectionTable
          inspections={inspectionsData}
          templates={templatesData ?? []}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </Stack>
  );
}
