/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      name
      address
      active
      user {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Templates {
        items {
          id
          name
          fields {
            type
            label
            value
            __typename
          }
          createdAt
          updatedAt
          User {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          templateUserId
          __typename
        }
        nextToken
        __typename
      }
      Inspections {
        items {
          id
          name
          area
          equipment
          site
          dueDate
          fields {
            type
            label
            value
            __typename
          }
          verifiers
          siteImages
          defects {
            values
            marker
            cameraImages
            __typename
          }
          status
          auditorsNote
          createdAt
          updatedAt
          businessID
          Business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      description
      permissions
      user {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userId
      email
      name
      role {
        items {
          id
          roleId
          userId
          role {
            id
            name
            description
            permissions
            user {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      business {
        items {
          id
          businessId
          userId
          business {
            id
            name
            address
            active
            user {
              nextToken
              __typename
            }
            Templates {
              nextToken
              __typename
            }
            Inspections {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userId
            email
            name
            role {
              nextToken
              __typename
            }
            business {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInspection = /* GraphQL */ `
  query GetInspection($id: ID!) {
    getInspection(id: $id) {
      id
      name
      area
      equipment
      site
      dueDate
      fields {
        type
        label
        value
        __typename
      }
      verifiers
      siteImages
      defects {
        values
        marker
        cameraImages
        __typename
      }
      status
      auditorsNote
      createdAt
      updatedAt
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listInspections = /* GraphQL */ `
  query ListInspections(
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        area
        equipment
        site
        dueDate
        fields {
          type
          label
          value
          __typename
        }
        verifiers
        siteImages
        defects {
          values
          marker
          cameraImages
          __typename
        }
        status
        auditorsNote
        createdAt
        updatedAt
        businessID
        Business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inspectionsByBusinessID = /* GraphQL */ `
  query InspectionsByBusinessID(
    $businessID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inspectionsByBusinessID(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        area
        equipment
        site
        dueDate
        fields {
          type
          label
          value
          __typename
        }
        verifiers
        siteImages
        defects {
          values
          marker
          cameraImages
          __typename
        }
        status
        auditorsNote
        createdAt
        updatedAt
        businessID
        Business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInspector = /* GraphQL */ `
  query GetInspector($id: ID!) {
    getInspector(id: $id) {
      id
      _inactive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listInspectors = /* GraphQL */ `
  query ListInspectors(
    $filter: ModelInspectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _inactive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      name
      fields {
        type
        label
        value
        __typename
      }
      createdAt
      updatedAt
      User {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      businessID
      Business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      templateUserId
      __typename
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        fields {
          type
          label
          value
          __typename
        }
        createdAt
        updatedAt
        User {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        businessID
        Business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        templateUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const templatesByBusinessID = /* GraphQL */ `
  query TemplatesByBusinessID(
    $businessID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByBusinessID(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fields {
          type
          label
          value
          __typename
        }
        createdAt
        updatedAt
        User {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        businessID
        Business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        templateUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusinessUser = /* GraphQL */ `
  query GetBusinessUser($id: ID!) {
    getBusinessUser(id: $id) {
      id
      businessId
      userId
      business {
        id
        name
        address
        active
        user {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Templates {
          items {
            id
            name
            fields {
              type
              label
              value
              __typename
            }
            createdAt
            updatedAt
            User {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            templateUserId
            __typename
          }
          nextToken
          __typename
        }
        Inspections {
          items {
            id
            name
            area
            equipment
            site
            dueDate
            fields {
              type
              label
              value
              __typename
            }
            verifiers
            siteImages
            defects {
              values
              marker
              cameraImages
              __typename
            }
            status
            auditorsNote
            createdAt
            updatedAt
            businessID
            Business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBusinessUsers = /* GraphQL */ `
  query ListBusinessUsers(
    $filter: ModelBusinessUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        userId
        business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const businessUsersByBusinessId = /* GraphQL */ `
  query BusinessUsersByBusinessId(
    $businessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessUsersByBusinessId(
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        userId
        business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const businessUsersByUserId = /* GraphQL */ `
  query BusinessUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        userId
        business {
          id
          name
          address
          active
          user {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Templates {
            items {
              id
              name
              createdAt
              updatedAt
              businessID
              templateUserId
              __typename
            }
            nextToken
            __typename
          }
          Inspections {
            items {
              id
              name
              area
              equipment
              site
              dueDate
              verifiers
              siteImages
              status
              auditorsNote
              createdAt
              updatedAt
              businessID
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleUser = /* GraphQL */ `
  query GetRoleUser($id: ID!) {
    getRoleUser(id: $id) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions
        user {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userId
        email
        name
        role {
          items {
            id
            roleId
            userId
            role {
              id
              name
              description
              permissions
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        business {
          items {
            id
            businessId
            userId
            business {
              id
              name
              address
              active
              createdAt
              updatedAt
              __typename
            }
            user {
              id
              userId
              email
              name
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoleUsers = /* GraphQL */ `
  query ListRoleUsers(
    $filter: ModelRoleUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleId
        userId
        role {
          id
          name
          description
          permissions
          user {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleUsersByRoleId = /* GraphQL */ `
  query RoleUsersByRoleId(
    $roleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoleUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleUsersByRoleId(
      roleId: $roleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roleId
        userId
        role {
          id
          name
          description
          permissions
          user {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleUsersByUserId = /* GraphQL */ `
  query RoleUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoleUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roleId
        userId
        role {
          id
          name
          description
          permissions
          user {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userId
          email
          name
          role {
            items {
              id
              roleId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          business {
            items {
              id
              businessId
              userId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
