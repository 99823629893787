import {
  Navbar as MantineNavbar,
  Group,
  useMantineColorScheme,
  useMantineTheme,
  Switch,
  Button,
  Text,
} from '@mantine/core';
import { Sun, SignOut, MoonStars } from '@phosphor-icons/react';
import { MainLinks } from './MainLinks';
import { useAuthContext } from 'contexts/UserContext';
import { useState } from 'react';

type props = {
  opened: boolean;
};

export function Navbar({ opened }: props) {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();

  const handleLogout = () => {
    setLoading(true);
    logout();
  };

  return (
    <MantineNavbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{
        sm: 200,
        lg: 300,
      }}
    >
      <MantineNavbar.Section grow mt="md">
        <MainLinks />
      </MantineNavbar.Section>
      <MantineNavbar.Section>
        <Group position="center">
          <Button
            leftIcon={<SignOut />}
            variant="subtle"
            size="sm"
            color="gray"
            onClick={handleLogout}
            loading={loading}
          >
            Logout
          </Button>
          <Switch
            checked={colorScheme === 'dark'}
            color={'orange'}
            onChange={() => toggleColorScheme()}
            size="sm"
            onLabel={<Sun size="1rem" color={theme.white} />}
            offLabel={<MoonStars size="1rem" color={theme.colors.gray[6]} />}
          />
          <Text fz="xs"> MineCheck © 2023</Text>
        </Group>
      </MantineNavbar.Section>
    </MantineNavbar>
  );
}
