import { useNavigate } from 'react-router';
import {
  MagnifyingGlass,
  Files,
  Briefcase,
  FolderSimpleLock,
  IdentificationBadge,
  UserList,
} from '@phosphor-icons/react';
import { useAuthContext } from '../../contexts/UserContext';
import { Box, NavLink } from '@mantine/core';
import { PATHS } from '../../routes/paths';
import { useLocation } from 'react-router-dom';

export function MainLinks() {
  const navigate = useNavigate();
  const { isAdmin } = useAuthContext();
  const location = useLocation();

  const baseUrls = [
    {
      url: PATHS.inspections,
      label: 'Inspections',
      icon: MagnifyingGlass,
    },
    { url: PATHS.templates, label: 'Templates', icon: Files },
  ];

  if (isAdmin) {
    baseUrls.push(
      { url: PATHS.authUsers, label: 'Auth Users', icon: IdentificationBadge },
      { url: PATHS.businesses, label: 'Businesses', icon: Briefcase },
      { url: PATHS.roles, label: 'Roles', icon: FolderSimpleLock },
      { url: PATHS.users, label: 'Users', icon: UserList },
    );
  }

  const currentActiveIndex = baseUrls.findIndex(
    item => item.url === location.pathname,
  );

  return (
    <Box>
      {baseUrls?.length &&
        baseUrls.map((item, index) => (
          <NavLink
            key={item.url}
            label={item?.label}
            icon={item?.icon ? <item.icon size="1rem" /> : null}
            active={index === currentActiveIndex}
            color="orange"
            onClick={() => navigate(item.url)}
          />
        ))}
    </Box>
  );
}
