// React and other external libraries
import { Formik } from 'formik';
import { useState } from 'react';
import { v4 as uuid_v4 } from 'uuid';
import * as Yup from 'yup';
import { Button, Group, Stack } from '@mantine/core';
// Styles
import 'react-medium-image-zoom/dist/styles.css';
// Internal modules and components
import { CreateBusinessInput, Business } from '../../API';
import { businessService } from '../../services/businessService';
import { FormikTextInput } from 'components/forms/ForikTextInput';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { UpdateBusinessInput } from 'API';
import { string } from 'validations';
import { FormikAddressInput } from 'components/forms/FormikAddressInput';

interface BusinessFormProps {
  businessData?: Business;
  closeModal: () => void;
}

const validationSchema = Yup.object().shape({
  name: string,
  address: Yup.string().min(5).max(180).required('Required').nullable(),
});

export const BusinessForm = ({
  businessData,
  closeModal,
}: BusinessFormProps) => {
  const [initialValues] = useState<CreateBusinessInput>(
    {
      name: businessData?.name ?? '',
      address: businessData?.address ?? '',
      id: businessData?.id ?? '',
      active: businessData?.active ?? false,
    } || { id: uuid_v4(), name: '', address: '', active: true },
  );

  const handleSubmit = async (values: CreateBusinessInput) => {
    if (values.id && businessData) {
      const response = await businessService.update(
        values.id,
        values as UpdateBusinessInput,
      );
      if (response.ok) closeModal();
    } else {
      const response = await businessService.create(values);
      if (response.ok) closeModal();
    }
  };

  return (
    <Formik<CreateBusinessInput>
      enableReinitialize={true} // This is needed to update the form when initialValues changes
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
      validationSchema={validationSchema}
    >
      {props => {
        const { dirty, isValid, isSubmitting, handleSubmit } = props;
        return (
          <form>
            <Stack>
              {/* NAME */}
              <FormikTextInput name={'name'} label={'Name'} />
              {/* <FormikTextInput name={'address'} label={'Address'} /> */}
              <FormikAddressInput name="address" country="AU" />
            </Stack>
            {/* DEV TOOLS */}
            {process.env.NODE_ENV === 'development' && (
              <DisplayFormikState {...props} />
            )}
            {/* ACTION BUTTONS */}
            <Group position="right" spacing={16}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
                color="orange"
                loading={isSubmitting}
                disabled={!isValid || !dirty}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button variant="outline" onClick={closeModal} color="orange">
                Cancel
              </Button>
            </Group>
          </form>
        );
      }}
    </Formik>
  );
};
