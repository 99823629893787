// External libraries
import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
// Internal types
import {
  GraphQLQuery,
  GraphQLSubscription,
} from 'aws-amplify/node_modules/@aws-amplify/api/lib-esm/types';
// Services
import { notificationService } from 'services/notificationService';

type ResponseType<T> = {
  [dataKey: string]: {
    items: T;
  };
};

type SubscriptionType<T> = {
  [dataKey: string]: T;
};

type GraphQLSubscriptionType = {
  list: any;
  listKey: string;
  onCreate: any;
  onCreateKey: string;
  onDelete: any;
  onDeleteKey: string;
  onUpdate: any;
  onUpdateKey: string;
  variables?: {};
};

export function useGraphQLSubscription<T extends { id: string }>({
  list,
  listKey,
  onCreate,
  onCreateKey,
  onDelete,
  onDeleteKey,
  onUpdate,
  onUpdateKey,
  variables,
}: GraphQLSubscriptionType): {
  data: T[] | null;
  isLoading: boolean;
  error: any;
} {
  const queryClient = useQueryClient();
  const queryFn = async () => {
    try {
      const result = await API.graphql<GraphQLQuery<ResponseType<T[]>>>(
        graphqlOperation(list, variables),
      );
      return result.data?.[listKey].items || null;
    } catch (err: any) {
      notificationService.error(err.errors[0].message);
      return err;
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: [listKey],
    queryFn,
  });

  useEffect(() => {
    const onCreateSub = API.graphql<GraphQLSubscription<SubscriptionType<T>>>(
      graphqlOperation(onCreate),
    ).subscribe({
      next: ({ value: { data } }) => {
        const createdData = data?.[onCreateKey];
        if (createdData) {
          queryClient.setQueryData<T[]>([listKey], prevData =>
            prevData ? [...prevData, createdData] : [createdData],
          );
        }
      },
    });

    const onUpdateSub = API.graphql<GraphQLSubscription<SubscriptionType<T>>>(
      graphqlOperation(onUpdate),
    ).subscribe({
      next: ({ value: { data } }) => {
        const updatedData = data?.[onUpdateKey];
        console.log('updated data', onUpdateKey, updatedData);
        if (updatedData && updatedData.id) {
          data &&
            queryClient.setQueryData<T[]>([listKey], prevData =>
              prevData
                ? prevData.map(item =>
                    item.id === updatedData.id ? updatedData : item,
                  )
                : [],
            );
        }
      },
    });

    const onDeleteSub = API.graphql<GraphQLSubscription<SubscriptionType<T>>>(
      graphqlOperation(onDelete),
    ).subscribe({
      next: ({ value: { data } }) => {
        const deletedData = data?.[onDeleteKey];
        if (deletedData && deletedData.id) {
          queryClient.setQueryData<T[]>(
            [listKey],
            prevData =>
              prevData?.filter(item => item.id !== deletedData.id) || [],
          );
        }
      },
    });

    return () => {
      onCreateSub.unsubscribe();
      onUpdateSub.unsubscribe();
      onDeleteSub.unsubscribe();
    };
  }, [
    onCreate,
    onCreateKey,
    onDelete,
    onDeleteKey,
    onUpdate,
    onUpdateKey,
    list,
    listKey,
    queryClient,
  ]);

  return { data: data ?? null, isLoading, error };
}
