import React, { useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { Box, Group, Text } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { DownloadSimple, X, CloudArrowUp } from '@phosphor-icons/react';

interface FormikImageUploadProps {
  name: string;
  label?: string;
}

const FormikImageUpload: React.FC<FormikImageUploadProps> = ({
  name,
  label,
}) => {
  const openRef = useRef<() => void>(null);

  return (
    <div style={{ marginBottom: 16 }}>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Box>
            <Dropzone
              openRef={openRef}
              accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
              onDrop={files => {
                form.setFieldValue(field.name, [...field.value, ...files]);
              }}
              style={{ minHeight: 100 }}
            >
              <Group position="center">
                <Dropzone.Accept>
                  <DownloadSimple size={48} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <X size={48} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <CloudArrowUp size={48} color="grey" />
                </Dropzone.Idle>
              </Group>
              <Text ta="center" fz="sm" mt="xl" color="grey">
                {label ||
                  'Drag and drop image or click to select image from your computer'}
              </Text>
            </Dropzone>
            {/* <Button
              className={classes.control}
              size="md"
              radius="xl"
              onClick={() => openRef.current?.()}
            >
              Select files
            </Button> */}
          </Box>
        )}
      </Field>
    </div>
  );
};

export default FormikImageUpload;
