import React from 'react';
import { Field, FieldProps } from 'formik';
import { DateInput } from '@mantine/dates';
import { Box, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { CalendarBlank } from '@phosphor-icons/react';

interface FormikDateInputProps {
  name: string;
  label: string;
  placeholder?: string;
  type: 'AWSDate' | 'AWSTimestamp';
  [key: string]: any;
}

const FormikDateInput: React.FC<FormikDateInputProps> = ({
  name,
  label,
  placeholder = 'Date input',
  type,
  ...restProps
}) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps) => {
      const value = field.value;
      return (
        <Box>
          <DateInput
            icon={<CalendarBlank />}
            value={
              value
                ? new Date(
                    value instanceof Date
                      ? value
                      : type === 'AWSTimestamp'
                      ? value * 1000
                      : value,
                  )
                : undefined
            }
            onChange={dateValue => {
              if (dateValue instanceof Date) {
                if (type === 'AWSTimestamp') {
                  form.setFieldValue(field.name, dayjs(dateValue).unix());
                } else {
                  form.setFieldValue(
                    field.name,
                    dayjs(dateValue).toISOString(),
                  );
                }
              } else {
                form.setFieldValue(field.name, dateValue);
              }
            }}
            label={label}
            placeholder={placeholder}
            max={400}
            mx="auto"
            {...restProps}
          />

          {meta.error && (
            <Text color="red" size="sm">
              {meta.error}
            </Text>
          )}
        </Box>
      );
    }}
  </Field>
);

export default FormikDateInput;
