import { CognitoUser } from 'amazon-cognito-identity-js';
import { createContext } from 'react';
import { useContext } from 'react';

type UserContextType = {
  cognitoUser: CognitoUser | null; // Define the user type if you have it
  groups: string[] | null;
  isAdmin: boolean;
  authBusiness: string[] | null;
  user: {
    email: string;
    id: string | null;
    name: string | null;
  } | null;
  logout: () => void;
  isInitialized: boolean;
};

export function useAuthContext() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('useAuthContext must be used within a UserProvider');
  }
  return context;
}
const UserContext = createContext<UserContextType | null>(null);
export const UserProvider = UserContext.Provider;
