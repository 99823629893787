import React from 'react';
import { modals } from '@mantine/modals';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  size?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  confirmLabel?: string;
  cancelLabel?: string;
  color?: string;
  onConfirm?: () => void;
}

export const modalService = {
  delete: ({
    title = 'Delete item',
    children = 'Are you sure you want to delete this item?',
    confirmLabel = 'Delete',
    cancelLabel = 'Cancel',
    color = 'red',
    size = 'md',
    onConfirm,
  }: ModalProps) => {
    modals.openConfirmModal({
      title,
      centered: true,
      children,
      labels: { confirm: confirmLabel, cancel: cancelLabel },
      confirmProps: { color },
      size,
      onConfirm,
    });
  },
  confirm: ({
    title = 'Confirm action',
    children = 'Are you sure you want to perform this action?',
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    color = 'orange',
    size = 'md',
    onConfirm,
  }: ModalProps) => {
    modals.openConfirmModal({
      title,
      centered: true,
      children,
      labels: { confirm: confirmLabel, cancel: cancelLabel },
      confirmProps: { color },
      size,
      onConfirm,
    });
  },
  form: ({
    title,
    children,
    size = 'md',
    onConfirm,
    onClose,
  }: ModalProps & { onClose: () => void }) => {
    modals.open({
      title,
      children,
      size,
    });
  },
  close: () => {
    modals.closeAll();
  },
};
