// Routes.tsx
import { Navigate } from 'react-router-dom';
import { useAuthContext } from 'contexts/UserContext';
import { PATHS } from './paths';

interface UnprotectedRouteProps {
  component: React.ComponentType<any>;
}

export const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useAuthContext();

  if (user) {
    return <Navigate to={PATHS.inspections} />;
  }

  return (
    <>
      <Component {...rest} />
    </>
  );
};
