// External libraries
import { Menu } from '@mantine/core';
import { useMemo } from 'react';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
// Internal types
import { Business } from 'API';
// Services
import { modalService } from 'services/modalService';
import { BusinessForm } from './BusinessForm';
import { businessService } from 'services/businessService';
import { PencilSimple, X } from '@phosphor-icons/react';

type props = {
  businesses: Business[] | null;
};

export function BusinessTable({ businesses }: props) {
  const data = useMemo(() => businesses, [businesses]);
  const columns = useMemo<MRT_ColumnDef<Business>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Address',
        accessorKey: 'address',
      },
      // {
      //   header: 'Fields',
      //   accessorKey: 'fields',
      //   Cell: ({ cell }) => <pre>{JSON.stringify(cell.getValue())}</pre>,
      // },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableRowActions: true,
    state: { isLoading: !data },
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          icon={<PencilSimple size={16} />}
          onClick={() => {
            modalService.form({
              title: 'Edit Business',
              size: 'xl',
              children: (
                <BusinessForm
                  businessData={row.original}
                  closeModal={modalService.close}
                />
              ),
              onClose: () => null,
            });
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          icon={<X size={16} />}
          onClick={() =>
            modalService.delete({
              title: `Delete ${row.original.name}?`,
              children: `Are you sure you want to delete ${row.original.name} business?`,
              onConfirm: () => businessService.remove(row.original.id),
            })
          }
        >
          Delete
        </Menu.Item>
      </>
    ),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
}
