import { Auth, API } from 'aws-amplify';
import { notificationService } from './notificationService';

class AdminService {
  private apiName = 'AdminQueries';

  private async adminQuery(
    path: string,
    dataKey?: string,
    params?: any,
    options?: any,
  ) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      };

      const response = await API.get(this.apiName, path, {
        queryStringParameters: params,
        headers,
      });
      if (response.error) {
        throw new Error(response.error);
      } else {
        if (!options.hideSuccessNotification) {
          notificationService.success('Success', path);
        }
        if (dataKey) {
          return response[dataKey];
        } else {
          return response;
        }
      }
    } catch (err: any) {
      notificationService.error(err.message || 'An error occurred.');
      throw err;
    }
  }

  public listUsers(limit: number) {
    return this.adminQuery(
      '/listUsers',
      'Users',
      { limit },
      { hideSuccessNotification: true },
    );
  }

  public signUserOut() {
    return this.adminQuery('/signUserOut');
  }

  public disableUser() {
    return this.adminQuery('/disableUser');
  }

  public addUserToGroup() {
    return this.adminQuery('/addUserToGroup');
  }

  public enableUser() {
    return this.adminQuery('/enableUser');
  }

  public confirmUserSignUp() {
    return this.adminQuery('/confirmUserSignUp');
  }
}

export const adminService = new AdminService();
