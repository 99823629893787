/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qatpaot9jb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fh2hz3jm4bdlbn4nck6bltmzlu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-24gxo2idk5djlhwcll4nczbrky",
    "aws_cognito_identity_pool_id": "us-east-1:c3342e77-cb3c-4be5-93f2-e3657611b348",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zez0A3bUO",
    "aws_user_pools_web_client_id": "25ma12n175o8r779l77rr7clgb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "solidus-bucket-dev164109-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
