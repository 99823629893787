import {
  ActionIcon,
  Box,
  Text,
  CloseButton,
  Group,
  Stack,
  TextInput,
} from '@mantine/core';
import { useField } from 'formik';
import {
  NumberCircleOne,
  NumberCircleTwo,
  NumberCircleThree,
  NumberCircleFour,
  NumberCircleFive,
  NumberCircleSix,
  ListPlus,
} from '@phosphor-icons/react';
import { ReactNode } from 'react';

type DropdownOptionsInputProps = {
  name: string;
  label: string;
  [key: string]: any;
};

export const DropdownOptionsInput: React.FC<DropdownOptionsInputProps> = ({
  name,
  label,
  ...restProps
}) => {
  const [field, , helpers] = useField<string>(name);
  const options = field.value ? field.value.split(';') : [''];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    helpers.setValue(newOptions.join(';'));
  };

  const handleAddOption = () => {
    const newOptions = [...options, ''];
    helpers.setValue(newOptions.join(';'));
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);
    helpers.setValue(newOptions.join(';'));
  };

  const getIconByIndex = (index: number): ReactNode => {
    switch (index) {
      case 0:
        return <NumberCircleOne />;
      case 1:
        return <NumberCircleTwo />;
      case 2:
        return <NumberCircleThree />;
      case 3:
        return <NumberCircleFour />;
      case 4:
        return <NumberCircleFive />;
      case 5:
        return <NumberCircleSix />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Group>
        <Text fz="sm">{label}</Text>
        <ActionIcon onClick={handleAddOption}>
          <ListPlus />
        </ActionIcon>
      </Group>
      <Stack>
        {options.map((option, index) => (
          <Group key={index}>
            <TextInput
              type="text"
              value={option}
              icon={getIconByIndex(index)}
              onChange={e => handleChange(e, index)}
              rightSection={
                <CloseButton onClick={() => handleRemoveOption(index)} />
              }
              {...restProps}
            />
          </Group>
        ))}
      </Stack>
    </Box>
  );
};
