import React from 'react';
import { Field, FieldProps } from 'formik';
import { Group, PinInput, Text } from '@mantine/core';

export const FormikPinInput = ({
  name,
  ...restProps
}: {
  name: string;
  [key: string]: any;
}) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps) => (
      <Group position="center">
        <PinInput
          length={6}
          value={field.value || ''}
          onChange={pin => form.setFieldValue(name, pin)}
          {...restProps}
        />
        {meta.touched && meta.error && (
          <Text color="red" fz={'sm'}>
            {meta.error}
          </Text>
        )}
      </Group>
    )}
  </Field>
);
