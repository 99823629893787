export const theme = {
  // colors: {
  //   'ocean-blue': [
  //     '#7AD1DD',
  //     '#5FCCDB',
  //     '#44CADC',
  //     '#2AC9DE',
  //     '#1AC2D9',
  //     '#11B7CD',
  //     '#09ADC3',
  //     '#0E99AC',
  //     '#128797',
  //     '#147885',
  //   ],
  //   'bright-pink': [
  //     '#F0BBDD',
  //     '#ED9BCF',
  //     '#EC7CC3',
  //     '#ED5DB8',
  //     '#F13EAF',
  //     '#F71FA7',
  //     '#FF00A1',
  //     '#E00890',
  //     '#C50E82',
  //     '#AD1374',
  //   ],
  //   brand: [
  //     '#fff5e1',
  //     '#ffebc2',
  //     '#ffd88a',
  //     '#ffc552',
  //     '#ffb31f',
  //     '#ff8c00',
  //     '#e57c00',
  //     '#b36b00',
  //     '#804f00',
  //     '#4e3300',
  //   ],
  //   accent: [
  //     '#e3f2fd',
  //     '#bbdefb',
  //     '#90caf9',
  //     '#64b5f6',
  //     '#42a5f5',
  //     '#2196f3',
  //     '#1e88e5',
  //     '#1976d2',
  //     '#1565c0',
  //     '#0d47a1',
  //   ],
  // },
  fontFamily: 'Gilroy, Roboto, sans-serif',
  // shadows: {
  //   xs: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  //   sm: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  //   md: '0px 8px 16px rgba(0, 0, 0, 0.15)',
  //   lg: '0px 12px 24px rgba(0, 0, 0, 0.2)',
  //   xl: '0px 18px 36px rgba(0, 0, 0, 0.25)',
  // },
};
