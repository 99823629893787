// External libraries
import { Formik } from 'formik';
import { v4 as uuid_v4 } from 'uuid';
import * as Yup from 'yup';
import { Button, Box, Stack, Group } from '@mantine/core';
// Internal libraries or utilities
import { clean, makeImgName } from 'utils/Helpers';
import { s3Service } from 'services/S3Service';
import { inspectionService } from 'services/inspectionService';
import { modalService } from 'services/modalService';
import ImagesCarousel from './ImagesCarousel';
// API Types
import { Inspection, Template, InspectionField } from 'API';
// Components
import { FormikTextInput } from '../forms/ForikTextInput';
import FormikDateInput from '../forms/FormikDateInput';
import FormikImageUpload from '../forms/FormikImageUpload';
import { FormikDragAndDropFieldArray } from '../forms/FormikDragAndDropFieldArray';
import {
  Engine,
  Factory,
  IdentificationBadge,
  Ruler,
  X,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { string } from '../../validations';
import { bucketURL } from 'config/bucketURL';
import { useAuthContext } from 'contexts/UserContext';

interface InspectionFormProps {
  inspectionData?: Inspection;
  templates?: Template[];
  closeModal: () => void;
}
interface InspectionFormValues {
  name: string;
  area: string;
  equipment: string;
  site: string;
  dueDate: number;
  siteImages?: (string | null)[];
  templates?: Template[];
  fields?: (InspectionField | null)[];
}

const validationSchema = Yup.object().shape({
  name: string.required(),
  area: Yup.string().required('Required'),
  equipment: Yup.string().required('Required'),
  site: Yup.string().required('Required'),
  dueDate: Yup.number().required('Required'),
});

export default function InspectionForm({
  closeModal,
  inspectionData,
  templates,
}: InspectionFormProps) {
  const { authBusiness } = useAuthContext();

  const initialValues: InspectionFormValues = {
    name: inspectionData?.name ?? '',
    area: inspectionData?.area ?? '',
    equipment: inspectionData?.equipment ?? '',
    site: inspectionData?.site ?? '',
    dueDate: inspectionData?.dueDate ?? dayjs().unix(),
    siteImages: inspectionData?.siteImages ?? [],
    templates: templates ?? [],
    fields: inspectionData?.fields ?? [],
  };

  const deletedImages: string[] = [];

  const handleSubmit = async (values: InspectionFormValues) => {
    const id = inspectionData?.id ?? uuid_v4();
    const imgs: string[] = [];
    values.siteImages?.forEach((img: any) => {
      if (typeof img !== 'string') {
        const name = makeImgName(img);
        imgs.push(`${id}/${name}`);
      } else {
        if (!img.includes(id)) {
          imgs.push(`${id}/${img}`);
        } else {
          imgs.push(img);
        }
      }
    });
    let payload = {
      id,
      ...clean(values, true),
      ...{
        siteImages: imgs,
      },
    };
    // !payload.defects && delete payload.defects;
    try {
      if (payload?.siteImages?.length !== 0) {
        // Upload images to S3
        const imgUploadResponse = await s3Service.uploadFile(
          values.siteImages as unknown as File[],
          imgs,
        );
        console.log('imgUploadResponse', imgUploadResponse);
      }
      // Edit inspection
      if (inspectionData) {
        // Delete images from S3
        if (deletedImages.length !== 0) {
          deletedImages.forEach(async img => {
            await s3Service.deleteFile(`${id}/${img}`);
          });
        }
        const response = await inspectionService.update(payload.id, payload);
        if (response.ok) closeModal();
      } else {
        const payload = {
          id,
          ...values,
          businessID: authBusiness?.[0] ?? '',
          ...{
            siteImages: imgs,
          },
        };
        // Create inspection
        const response = await inspectionService.create(payload);
        if (response.ok) closeModal();
      }
    } catch (error) {
      console.log('error saving inspection', error);
    }
  };

  return (
    <Formik<InspectionFormValues>
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
      validationSchema={validationSchema}
    >
      {props => {
        const { values, dirty, isValid, isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit}>
            <Stack>
              <Group>
                <FormikTextInput
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  icon={<IdentificationBadge />}
                  style={{ flex: 1 }}
                />
                <FormikTextInput
                  name="area"
                  label="Area"
                  placeholder="Enter area"
                  icon={<Ruler />}
                  style={{ flex: 1 }}
                />
                <FormikTextInput
                  name="site"
                  label="Site"
                  placeholder="Enter site"
                  icon={<Factory />}
                  style={{ flex: 1 }}
                />
              </Group>
              <Group>
                <FormikTextInput
                  name="equipment"
                  label="Equipment"
                  placeholder="Enter equipment"
                  icon={<Engine />}
                  style={{ flex: 1 }}
                />
                <FormikDateInput
                  name="dueDate"
                  label="Due Date"
                  type="AWSTimestamp"
                  placeholder="Enter date"
                  style={{ flex: 1 }}
                />
              </Group>
              <Box>
                <FormikDragAndDropFieldArray />
              </Box>
              <FormikImageUpload name="siteImages" />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 16,
                }}
              >
                {values.siteImages?.map((image, index) => (
                  <Box
                    key={image?.toString()}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 4,
                    }}
                  >
                    {typeof image === 'string' ? (
                      <img
                        src={`${bucketURL}/${image}`}
                        width={100}
                        alt=""
                        style={{ borderRadius: 4, cursor: 'pointer' }}
                        onClick={() => {
                          modalService.form({
                            title: 'Images',
                            size: 'lg',
                            children: (
                              <ImagesCarousel
                                images={values.siteImages as any[]}
                                currentIndex={index}
                              />
                            ),
                            onClose: () => null,
                          });
                        }}
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(image as any)}
                        width={100}
                        alt=""
                        style={{ borderRadius: 4, cursor: 'pointer' }}
                        onClick={() => {
                          modalService.form({
                            title: 'Images',
                            size: 'xl',
                            children: (
                              <ImagesCarousel
                                images={values.siteImages as any[]}
                                currentIndex={index}
                              />
                            ),
                            onClose: () => null,
                          });
                        }}
                      />
                    )}
                    <Button
                      color="orange"
                      variant="outline"
                      size="xs"
                      onClick={() => {
                        const newImages = [...(values.siteImages as string[])];
                        newImages.splice(index, 1);
                        props.setFieldValue('siteImages', newImages);
                        if (typeof image === 'string') {
                          deletedImages.push(image);
                        }
                      }}
                      leftIcon={<X />}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
              </div>
            </Stack>
            {/* DEV TOOLS */}
            {/* {process.env.NODE_ENV === 'development' && (
              <DisplayFormikState {...props} />
            )} */}
            {/* ACTION BUTTONS */}
            <Group position="right" spacing={16}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
                color="orange"
                disabled={!isValid || !dirty}
                loading={isSubmitting}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button variant="outline" onClick={closeModal} color="orange">
                Cancel
              </Button>
            </Group>
          </form>
        );
      }}
    </Formik>
  );
}
