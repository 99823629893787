import { createRef } from 'react';
import { Carousel } from '@mantine/carousel';
import { Group } from '@mantine/core';
import { bucketURL } from 'config/bucketURL';
import { Inspection } from 'API';

const imageLoaded = (
  ev: any,
  canvasRef: { current: { getContext: (arg0: string) => any } },
  marker: { left: any; top: any },
) => {
  var canvasContext = canvasRef?.current?.getContext('2d');
  let image = ev.target;
  try {
    marker = JSON.parse(marker as unknown as string);
    marker.top = Math.floor(marker.top);
    marker.left = Math.floor(marker.left);
  } catch (e) {
    console.error('Error parsing marker', marker);
  }
  image.crossOrigin = 'anonymous';
  if (!image.src.includes('?not_from_cache_please')) {
    image.src = image.src + '?not_from_cache_please';
  }

  let canvasWidth = 350,
    canvasHeight = 350;

  let scale = Math.min(
      canvasWidth / image.naturalWidth,
      canvasHeight / image.naturalHeight,
    ),
    x = canvasWidth / 2 - (image.naturalWidth / 2) * scale,
    y = canvasHeight / 2 - (image.naturalHeight / 2) * scale;

  if (canvasContext) {
    canvasContext.drawImage(
      image,
      x,
      y,
      image.naturalWidth * scale,
      image.naturalHeight * scale,
    );
    canvasContext.fillStyle = 'black';
    canvasContext.font = '50px Arial';
    canvasContext.globalCompositeOperation = 'destination-over';
    canvasContext.fillStyle = '#FFFFFF';
    canvasContext.fillRect(0, 0, canvasWidth, canvasHeight); //for white background
    canvasContext.globalCompositeOperation = 'source-over';
    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = '#000000';
    // Show dot
    canvasContext.fillStyle = '#C00000';
    canvasContext.fillRect(marker.left, marker.top, 10, 10);

    image.remove();
  }
};

export default function DefectsCarousel({
  inspection,
}: {
  inspection: Inspection;
}) {
  if (inspection.defects) {
    inspection.defects.forEach((defect: any) => {
      defect.imgRef = createRef<HTMLImageElement>();
      defect.canvasRef = createRef<HTMLCanvasElement>();
    });
  }
  return (
    <div>
      {inspection.defects && inspection?.defects?.length > 0 && (
        <Carousel
          withControls={inspection?.defects?.length > 1}
          style={{ height: 350 }}
          loop
        >
          {inspection?.defects?.map((defect: any, index: any) => (
            <Carousel.Slide key={index}>
              <Group position="center" style={{ height: 350 }}>
                <>
                  <img
                    src={`${bucketURL}/${inspection?.siteImages?.[index]}`}
                    alt=""
                    style={{ display: 'none' }}
                    width={350}
                    onLoad={ev =>
                      imageLoaded(ev, defect.canvasRef, defect.marker)
                    }
                  />
                  <canvas
                    ref={defect.canvasRef}
                    width="350"
                    height="350"
                    style={{ borderRadius: 4 }}
                  />
                </>
              </Group>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
    </div>
  );
}
