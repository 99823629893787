import {
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Flex,
  Title,
} from '@mantine/core';
import { useAuthContext } from 'contexts/UserContext';

type props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const hexToRGBA = (hex: string, alpha: number = 1): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return hex; // If hex doesn't match the pattern, return it as is
};

export const NavHeader = ({ opened, setOpened }: props) => {
  const theme = useMantineTheme();
  const { user } = useAuthContext();
  if (!user) return null;

  return (
    <Header
      height={50}
      style={{
        backgroundColor: hexToRGBA(theme.colors.orange[1], 0.3), // 0.3 is the alpha value
        backdropFilter: 'blur(10px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 15px', // padding left and right
      }}
    >
      <Flex align="center" justify="center" direction="row">
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={o => setOpened(opened => !opened)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>
        {/* <img src={logo} alt="" height={'28px'} /> */}
        <Title order={4} color={theme.colors.orange[6]}>
          MineCheck
        </Title>
      </Flex>

      <Text size="sm" color={theme.colors.orange[5]}>
        {user.email}
      </Text>
    </Header>
  );
};
