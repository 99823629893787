// React and other external libraries
import { Plus } from '@phosphor-icons/react';
import { Button, Stack, Group } from '@mantine/core';
// Internal modules and components
import { BusinessTable } from 'components/business/BusinessTable';
import { BusinessForm } from 'components/business/BusinessForm';
import { modalService } from 'services/modalService';
import { useGraphQLSubscription } from 'hooks/useGraphQLSubscription';
import {
  onCreateBusiness,
  onDeleteBusiness,
  onUpdateBusiness,
} from 'graphql/subscriptions';
import { listBusinesses } from 'graphql/queries';
import { Business } from 'API';

export default function Businesss() {
  const { data: businesses } = useGraphQLSubscription<Business>({
    list: listBusinesses,
    listKey: 'listBusinesses',
    onCreate: onCreateBusiness,
    onCreateKey: 'onCreateBusiness',
    onUpdate: onUpdateBusiness,
    onUpdateKey: 'onUpdateBusiness',
    onDelete: onDeleteBusiness,
    onDeleteKey: 'onDeleteBusiness',
  });

  return (
    <Stack>
      <Group position="right">
        {/* CREATE */}
        <Button
          leftIcon={<Plus size={18} />}
          color="orange"
          onClick={() => {
            modalService.form({
              title: 'Create Business',
              size: 'xl',
              children: <BusinessForm closeModal={modalService.close} />,
              onClose: () => null,
            });
          }}
        >
          Create
        </Button>
      </Group>
      {/* TABLE */}
      <BusinessTable businesses={businesses} />
    </Stack>
  );
}
