import React from 'react';
import { Container, Paper, Title, Text } from '@mantine/core';

const PrivacyPolicy = () => {
  return (
    <Container size="sm">
      <Paper shadow="xs">
        <Title
          order={3}
          align="center"
          weight={700}
          style={{ marginBottom: '20px' }}
        >
          Privacy Policy for MineCheck
        </Title>

        <Text fz="md" align="justify">
          Last updated: September 13, 2023
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          1. Information We Collect
        </Title>

        <Text fz="md" align="justify">
          <Text fz="sm">a.</Text> <Text fz="md">Personal Information:</Text>{' '}
          When you use MineCheck, we may collect and store the following
          personal information:
          <Text fz="sm">- Name</Text>
          <Text fz="sm">- Email Address</Text>
        </Text>

        <Text fz="md" align="justify">
          <Text fz="sm">b.</Text> <Text fz="md">Device Camera Access:</Text> To
          enable equipment inspection features, MineCheck may request access to
          your device's camera.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          2. How We Use Your Information
        </Title>

        <Text fz="md" align="justify">
          We use the information collected for the following purposes:
        </Text>

        <Text fz="md" align="justify">
          <Text fz="sm">a.</Text> <Text fz="md">Equipment Inspection:</Text>{' '}
          Your name and email may be used to create and manage your MineCheck
          account. Access to your device's camera is required for equipment
          inspection features to function.
        </Text>

        <Text fz="md" align="justify">
          <Text fz="sm">b.</Text> <Text fz="md">Communication:</Text> We may use
          your email address to send you important updates, notifications, and
          relevant information related to your use of the MineCheck application.
        </Text>

        <Text fz="md" align="justify">
          <Text fz="sm">c.</Text> <Text fz="md">Improvement:</Text> We may use
          aggregated and anonymized data for analytical purposes to enhance the
          functionality and performance of the application.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          3. Data Processing
        </Title>

        <Text fz="md" align="justify">
          We process and store your information on servers located in the United
          States. By using the MineCheck application, you consent to this data
          processing and storage.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          4. Disclosure of Your Information
        </Title>

        <Text fz="md" align="justify">
          We do not sell, rent, or lease your personal information to third
          parties.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          5. Security
        </Title>

        <Text fz="md" align="justify">
          We take the security of your information seriously and have
          implemented reasonable safeguards to protect it. However, please be
          aware that no method of transmission or storage is completely secure,
          and we cannot guarantee the absolute security of your data.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          6. Your Choices
        </Title>

        <Text fz="md" align="justify">
          <Text fz="sm">a.</Text> <Text fz="md">Access and Correction:</Text>{' '}
          You may access and correct your personal information through the
          MineCheck application settings.
        </Text>

        <Text fz="md" align="justify">
          <Text fz="sm">b.</Text> <Text fz="md">Opt-Out:</Text> You can opt-out
          of receiving non-essential communications from us by following the
          unsubscribe instructions included in our emails.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          7. Changes to this Privacy Policy
        </Title>

        <Text fz="md" align="justify">
          We may update this Privacy Policy to reflect changes in our practices
          or for other operational, legal, or regulatory reasons. We will notify
          you of any material changes via email or through the application.
          Please review this policy periodically to stay informed about our data
          practices.
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          8. Contact Us
        </Title>

        <Text fz="md" align="justify">
          If you have any questions, concerns, or requests related to this
          Privacy Policy or your personal information, please contact us at:
        </Text>

        <Text fz="md" align="justify">
          MineCheck Pty Ltd
        </Text>

        <Text fz="md" align="justify">
          Address: [Your Address]
        </Text>

        <Text fz="md" align="justify">
          Email: [Your Email Address]
        </Text>

        <Text fz="md" align="justify">
          Phone: [Your Phone Number]
        </Text>

        <Title order={3} style={{ marginTop: '20px' }}>
          Conclusion
        </Title>

        <Text fz="md" align="justify">
          Thank you for using MineCheck. We are committed to safeguarding your
          privacy and ensuring the security of your personal information. By
          using our application, you agree to the terms and conditions outlined
          in this Privacy Policy.
        </Text>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
