import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Box, Text, Group } from '@mantine/core';
import { DisplayFormikState } from 'components/forms/DisplayFormikState';
import { FormikTextInput } from 'components/forms/ForikTextInput';
import { Auth } from 'aws-amplify';
import { FormikPasswordInput } from 'components/forms/FormikPasswordInput';
import { notificationService } from 'services/notificationService';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';

type LoginFormProps = {
  email: string;
  password: string;
  name: string;
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const handleSubmit = async (
    { email, password, name }: LoginFormProps,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      await Auth.signIn(email, password);
      setSubmitting(false);
      navigate(PATHS.inspections);
    } catch (error: any) {
      setSubmitting(false);
      notificationService.error(error.message);
      console.log(error);
    }
  };

  return (
    <Formik<LoginFormProps>
      initialValues={{ email: '', password: '', name: '' }}
      validationSchema={validationSchema}
      onSubmit={({ email, password, name }, { setSubmitting }) =>
        handleSubmit({ email, password, name }, setSubmitting)
      }
    >
      {props => {
        const { isSubmitting, isValid } = props;
        return (
          <>
            <Form>
              <Box>
                <FormikTextInput
                  label="Email address"
                  name="email"
                  placeholder="hello@gmail.com"
                />
              </Box>
              <Box>
                <FormikPasswordInput label="Password" name="password" />
              </Box>
              <Button
                type="submit"
                color="orange"
                fullWidth
                mt="xl"
                size="md"
                loading={isSubmitting}
                disabled={!isValid}
              >
                Login
              </Button>

              <Group
                mt={'xl'}
                position="right"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(PATHS.register)}
              >
                <Text>Don't have an account? </Text>
                <Text fw={600}>Register</Text>
              </Group>

              {/* DEV TOOLS */}
              {process.env.NODE_ENV === 'development' && (
                <DisplayFormikState {...props} />
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
