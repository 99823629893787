import { Stack } from '@mantine/core';
import { useGraphQLSubscription } from 'hooks/useGraphQLSubscription';
import {
  onCreateUser,
  onDeleteUser,
  onUpdateUser,
} from 'graphql/subscriptions';
import { listUsers } from 'graphql/queries';
import { User } from 'API';
import UsersTable from 'components/users/usersTable';

export function Users() {
  const { data: users } = useGraphQLSubscription<User>({
    list: listUsers,
    listKey: 'listUsers',
    onCreate: onCreateUser,
    onCreateKey: 'onCreateUser',
    onUpdate: onUpdateUser,
    onUpdateKey: 'onUpdateUser',
    onDelete: onDeleteUser,
    onDeleteKey: 'onDeleteUser',
  });

  return (
    <Stack>
      {/* TABLE */}
      <UsersTable users={users} />
    </Stack>
  );
}
