import { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  Select,
  Button,
  Box,
  Group,
  Stack,
  Paper,
  Divider,
  ActionIcon,
} from '@mantine/core';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { FormikTextInput } from './ForikTextInput';
import {
  DotsSixVertical,
  ListPlus,
  MinusCircle,
  Tag,
} from '@phosphor-icons/react';
import { DropdownOptionsInput } from 'components/forms/DropdownOptionsInput';
import { Template } from 'API';

type MyFormValues = {
  fields: { type: string; label: string; value: string }[];
  templates: Template[];
};

type DragAndDropProps = {
  type?: 'create' | 'select';
};

export const FormikDragAndDropFieldArray: React.FC<DragAndDropProps> = ({
  type = 'select',
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const { values, setFieldValue } = useFormikContext<MyFormValues>();

  return (
    <FieldArray name="fields">
      {({ remove, push }) => (
        <>
          <DragDropContext
            onDragEnd={({ destination, source }) => {
              if (!destination || source.index === destination.index) return;
              const reorderedFields = Array.from(values.fields);
              const [moved] = reorderedFields.splice(source.index, 1);
              reorderedFields.splice(destination.index, 0, moved);
              setFieldValue('fields', reorderedFields);
            }}
          >
            <Droppable droppableId="droppable" direction="vertical">
              {provided => (
                <Stack {...provided.droppableProps} ref={provided.innerRef}>
                  {values.fields?.map((field, index) => (
                    <Box key={index.toString()}>
                      <Draggable draggableId={index.toString()} index={index}>
                        {provided => (
                          <Paper pb={12}>
                            <Group
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              spacing={16}
                              position="apart"
                              align="center"
                            >
                              <Group spacing={16} align="center">
                                <Box
                                  {...provided.dragHandleProps}
                                  style={{ cursor: 'grab', display: 'flex' }}
                                >
                                  <DotsSixVertical
                                    color="orange"
                                    weight="bold"
                                    size={24}
                                  />
                                </Box>
                                <Select
                                  label="Type"
                                  data={[
                                    { value: 'dropdown', label: 'Dropdown' },
                                    { value: 'text', label: 'Text Input' },
                                    {
                                      value: 'image_capture',
                                      label: 'Image Capture',
                                    },
                                    {
                                      value: 'external_images',
                                      label: 'External Images Names',
                                    },
                                  ]}
                                  onChange={item =>
                                    setFieldValue(`fields.${index}.type`, item)
                                  }
                                  value={field.type}
                                />
                                <FormikTextInput
                                  name={`fields.${index}.label`}
                                  label="Label"
                                  placeholder="Enter label"
                                  icon={<Tag />}
                                />
                                {field.type === 'dropdown' && (
                                  <DropdownOptionsInput
                                    name={`fields.${index}.value`}
                                    label="Options"
                                    placeholder="Enter option"
                                  />
                                )}
                              </Group>
                              <ActionIcon onClick={() => remove(index)}>
                                <MinusCircle size={24} />
                              </ActionIcon>
                            </Group>
                          </Paper>
                        )}
                      </Draggable>
                      <Divider />
                    </Box>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
          {type === 'create' && (
            <Button
              variant="outline"
              color="dark"
              leftIcon={<ListPlus />}
              style={{ marginTop: 10 }}
              onClick={() =>
                push({
                  type: 'dropdown',
                  label: '',
                  value: '',
                })
              }
            >
              Add Item
            </Button>
          )}
          {type === 'select' && (
            <Select
              label="From Template"
              data={values.templates.map((template: Template) => ({
                value: template.id,
                label: template.name,
              }))}
              value={selectedTemplate}
              onChange={id => {
                const template = values.templates.find(
                  (template: Template) => template.id === id,
                );
                if (template) {
                  setFieldValue('fields', template.fields);
                  setSelectedTemplate(id);
                }
              }}
            />
          )}
        </>
      )}
    </FieldArray>
  );
};
