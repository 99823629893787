// React and other external libraries
import { Plus } from '@phosphor-icons/react';
import { Button, Stack, Group } from '@mantine/core';
// Internal modules and components
import { Template } from '../API';
import { TemplatesTable } from 'components/templates/TemplatesTable';
import { TemplateForm } from 'components/templates/TemplateForm';
import { modalService } from 'services/modalService';
import { useGraphQLSubscription } from 'hooks/useGraphQLSubscription';
import {
  onCreateTemplate,
  onDeleteTemplate,
  onUpdateTemplate,
} from 'graphql/subscriptions';
import { listTemplates } from 'graphql/queries';
import { useAuthContext } from 'contexts/UserContext';

export default function Templates() {
  const { authBusiness } = useAuthContext();
  const { data: templatesData } = useGraphQLSubscription<Template>({
    list: listTemplates,
    listKey: 'listTemplates',
    onCreate: onCreateTemplate,
    onCreateKey: 'onCreateTemplate',
    onUpdate: onUpdateTemplate,
    onUpdateKey: 'onUpdateTemplate',
    onDelete: onDeleteTemplate,
    onDeleteKey: 'onDeleteTemplate',
    variables: {
      filter: {
        or: authBusiness
          ? authBusiness?.map(business => ({
              businessID: {
                eq: business,
              },
            }))
          : {
              businessID: {
                eq: 'NOT_AUTHORIZED',
              },
            },
      },
    },
  });

  return (
    <Stack>
      <Group position="right">
        {/* CREATE */}
        <Button
          leftIcon={<Plus size={18} />}
          color="orange"
          onClick={() => {
            modalService.form({
              title: 'Create Template',
              size: 'xl',
              children: <TemplateForm closeModal={modalService.close} />,
              onClose: () => null,
            });
          }}
        >
          Create
        </Button>
      </Group>
      {/* TABLE */}
      {authBusiness && authBusiness?.length > 0 && (
        <TemplatesTable templates={templatesData} />
      )}
    </Stack>
  );
}
