import { Group, Title } from '@mantine/core';
import { CaretLeft } from '@phosphor-icons/react';
import { RegisterForm } from 'components/auth/RegisterForm';
import { AuthLayout } from 'layout/auth/AuthLayout';
import { PATHS } from 'routes/paths';
import { Link } from 'react-router-dom';

export function Register() {
  return (
    <AuthLayout
      title={
        <Group>
          <Link to={PATHS.login}>
            <CaretLeft size={40} weight="bold" color="orange" />
          </Link>
          <Title ta={'center'}>Welcome to MineCheck</Title>
        </Group>
      }
    >
      <RegisterForm />
    </AuthLayout>
  );
}
