import { UpdateInspectionInput } from 'API';
type ObjectLiteral = { [key: string]: any };
interface Image {
  name: string;
  file?: File;
}

type HqDefectLocationProps = UpdateInspectionInput & {
  id?: string;
  imgRef: React.RefObject<HTMLImageElement>;
  marker: any;
};

export function removeEmpty(obj: any) {
  return Object.keys(obj)
    .filter(function (k) {
      return obj[k] != null && obj[k] !== '';
    })
    .reduce(function (acc: any, k) {
      acc[k] = typeof obj[k] === 'object' ? removeEmpty(obj[k]) : obj[k];
      return acc;
    }, {});
}

export const flatten = (
  obj: ObjectLiteral,
  roots: string[] = [],
  sep = '.',
): ObjectLiteral =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ? flatten(obj[prop], roots.concat([prop]))
          : { [roots.concat([prop]).join(sep)]: obj[prop] },
      ),
    {},
  );

export const clean = (
  object: ObjectLiteral,
  remove_empty_strings?: boolean,
): ObjectLiteral => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') clean(v);
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      (remove_empty_strings && v === '')
    ) {
      if (Array.isArray(object)) {
        object.splice(Number(k), 1);
      } else if (!(v instanceof Date)) {
        delete object[k];
      }
    }
  });
  return object;
};

export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const randomString = (length: number) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const kebabCase = (string: string) => {
  var result = string;

  // Convert camelCase capitals to kebab-case.
  result = result.replace(/([a-z][A-Z])/g, function (match) {
    return match.substr(0, 1) + '-' + match.substr(1, 1).toLowerCase();
  });

  // Convert non-camelCase capitals to lowercase.
  result = result.toLowerCase();

  // Convert non-alphanumeric characters to hyphens
  result = result.replace(/[^a-z0-9\s]/g, '');
  result = result.replace(/[\s]/g, '-');

  // Remove hyphens from both ends
  result = result.replace(/^-+/, '').replace(/-+$/, '');

  // Remove multiple hyphens
  result = result.replace(/-+/g, '-');

  return result;
};

export const makeImgName = (image: { path: string }): string => {
  const newName = image.path.replace(/\s/g, '_');
  return `${kebabCase(newName.split(/\.(?=[^.]+$)/)[0])}.${
    newName.split(/\.(?=[^.]+$)/)[1]
  }`;
};

/**
 *
 * @param {array} images Array of file objects [{name: string, file: file, data_url: string}]
 * @param {AmplifyStorage} Storage Amplify Storage object
 * @param {string} baseUrl storeId + '/products','/store',etc...
 * @returns
 */
export const uploadImages = (images: Image[], Storage: any): Promise<void> => {
  return new Promise(async resolve => {
    Storage.configure({
      AWSS3: {
        bucket: 'solidus-bucket-dev164109-dev',
        region: 'us-east-1',
      },
    });
    for (let i = 0; i < images.length; i++) {
      let image = images[i];

      console.log(image);
      if (image.file) {
        try {
          await Storage.put(image.name, image.file, {
            contentType: image.file.type,
          });
        } catch (e) {
          console.log('Error uploading image', e);
        }
      }
    }

    resolve();
  });
};

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const hqDefectLocation = (defect: HqDefectLocationProps) => {
  // Create canvas element.
  var canvas = document.createElement('canvas');

  // Get canvas context.
  var context = canvas.getContext('2d');

  var image = defect.imgRef?.current;
  // Set image size, must use image.naturalWidth and image.naturalHeight -- not image.width and image.height.
  if (image) {
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    //
    // Canvas dimensions
    const canvasWidth = Math.max(image.naturalHeight, image.naturalWidth);
    const canvasHeight = Math.max(image.naturalHeight, image.naturalWidth);

    // Set canvas size.
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Set scale to fit image to canvas,
    const scale = Math.min(
      canvasWidth / imageWidth,
      canvasHeight / imageHeight,
    );

    // Set new image dimensions.
    const scaledWidth = imageWidth * scale;
    const scaledHeight = imageHeight * scale;

    if (context) {
      // Draw image in center of canvas.
      context.drawImage(
        image,
        (canvasWidth - scaledWidth) / 2,
        (canvasHeight - scaledHeight) / 2,
        scaledWidth,
        scaledHeight,
      );
      context.fillStyle = 'black';
      context.font = '50px Arial';
      context.globalCompositeOperation = 'destination-over';
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, canvasWidth, canvasHeight); //for white background
      context.globalCompositeOperation = 'source-over';
      context.lineWidth = 2;
      context.strokeStyle = '#000000';
      context.strokeRect(0, 0, canvasWidth, canvasHeight); //for white background
      // Show dot
      context.fillStyle = '#C00000';
      //
      //
      // New Marker location
      if (defect.marker && defect.marker[0]) {
        const marker = JSON.parse(defect.marker[0]); // Assuming the shape of the object
        const left = (marker.left / 350) * canvasWidth;
        const top = (marker.top / 350) * canvasHeight;

        context.fillRect(
          left,
          top,
          (10 / 350) * canvasWidth * 0.6,
          (10 / 350) * canvasWidth * 0.6,
        );
      }
    }
    let durl = canvas.toDataURL('image/png');
    canvas.remove();

    return durl;
  }
};
