import React, { useState, useEffect, useMemo } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Loader, TextInput, Radio } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { notificationService } from 'services/notificationService';
import axios from 'axios';
import { MapPinLine } from '@phosphor-icons/react';

interface AddressInputProps {
  name: string;
  country: string;
}

export const FormikAddressInput = ({ name, country }: AddressInputProps) => {
  const { setFieldValue, values } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [value, setValue] = useState('');
  const [debounced] = useDebouncedValue(value, 1000);

  const unsafeValues = values as any;

  const fetchAddressSuggestions = useMemo(
    () => async (query: string) => {
      if (!query) return;

      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            query,
          )}&countrycodes=${country}&format=json&addressdetails=1&limit=5`,
        );

        setIsLoading(false);
        setSuggestions(response.data);
        return response.data;
      } catch (error: any) {
        setIsLoading(false);
        console.error(error);
        notificationService.error(error.message);
      }
    },
    [country],
  );

  useEffect(() => {
    fetchAddressSuggestions(debounced);
  }, [fetchAddressSuggestions, debounced]);

  // Set initial value when component mounts
  useEffect(() => {
    if (unsafeValues[name]) {
      setValue(unsafeValues[name]);
    }
  }, [unsafeValues, name]);

  return (
    <>
      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <TextInput
            value={value}
            onChange={e => {
              setValue(e.currentTarget.value);
              setFieldValue(name, e.currentTarget.value);
            }}
            placeholder="Enter address"
            error={meta.touched && meta.error ? meta.error : undefined}
            icon={isLoading ? <Loader size={'xs'} /> : <MapPinLine />}
          />
        )}
      </Field>
      {suggestions.length > 0 && (
        <Radio.Group
          value={value}
          onChange={value => {
            setValue(value);
            setFieldValue(name, value);
          }}
          name="address"
          label="Select address"
          description="Or enter address manually"
        >
          {suggestions.map((suggestion, index) => (
            <Radio
              key={index}
              value={suggestion.display_name}
              label={suggestion.display_name}
            />
          ))}
        </Radio.Group>
      )}
    </>
  );
};
